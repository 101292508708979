import { useAppSelector } from "./hooks";
import {
  getFrontScreenCrackedTest,
  getTestClassifier,
  getPrecision,
  getAccuracy,
  getRecall,
  getF1Score,
  getTestReclassifier,
} from "helpers/transactionsHelper";
import { OTHER_TAGS } from "models/constants";
import { ALL } from "../views/GroundTruthReportPage/constants";
import { PredictionOutcomeType } from "helpers/transaction.types";

export const useReportData = (filteredOption: string) => {
  const selectedTenantCode = useAppSelector(
    (state) => state.transactions.filters.tenantCode,
  );
  const submittedTransactions = useAppSelector(
    (state) => state.transactions.submittedTransactions,
  );

  const allTransactions = useAppSelector(
    (state) => state.transactions.allTransactions,
  );

  // remove last object of other comments from report
  let All_REPORT_TAGS = { ...OTHER_TAGS };
  delete All_REPORT_TAGS.OTHER_COMMENTS;

  const transactionSummary = {
    total: allTransactions.length,
    submitted: allTransactions.filter(
      (txn) => txn.assessmentStatus === "submitted",
    ).length,
    pending: allTransactions.filter((txn) => txn.assessmentStatus === "created")
      .length,
    inProgress: allTransactions.filter(
      (txn) => txn.assessmentStatus === "in_progress",
    ).length,
  };

  const submittedTransactionsAboveThreshold = submittedTransactions.filter(
    (txn) => {
      const test = getFrontScreenCrackedTest(txn);
      if (test?.results?.crackedProbability) {
        return test?.results?.crackedProbability > 0.5;
      }
      return false;
    },
  ).length;

  const crackedScreenTestMLModelResult = {
    aboveThreshold: submittedTransactionsAboveThreshold,
    belowThreshold:
      submittedTransactions.length - submittedTransactionsAboveThreshold,
  };

  let humanEyePredictionOutcome: { [id: string]: any } = {
    TP: 0,
    TN: 0,
    FP: 0,
    FN: 0,
  };

  let C2PBehaviourPredictionOutcome: { [id: string]: any } = {
    TP: 0,
    TN: 0,
    FP: 0,
    FN: 0,
  };

  submittedTransactions.forEach((txn) => {
    const test = getFrontScreenCrackedTest(txn);
    const classifier = getTestClassifier(test, selectedTenantCode);
    const reClassifier = getTestReclassifier(test, selectedTenantCode);
    humanEyePredictionOutcome[classifier]++;
    C2PBehaviourPredictionOutcome[reClassifier]++;
  });

  let otherObservations: { [id: string]: any } = {};

  Object.keys(All_REPORT_TAGS).forEach((tag) => {
    otherObservations[tag] = { categorisation: OTHER_TAGS[tag] };
    otherObservations[tag].occurence = 0;

    submittedTransactions.forEach((txn) => {
      const test = getFrontScreenCrackedTest(txn);
      const classifier = getTestClassifier(test, selectedTenantCode);

      if (test?.derived?.tags.includes(OTHER_TAGS[tag])) {
        if (filteredOption === classifier || filteredOption === ALL) {
          otherObservations[tag].occurence += 1;
        }
      }
    });

    const tagPercentOccurrence =
      (otherObservations[tag].occurence / submittedTransactions.length) * 100;

    otherObservations[tag].overallPercentage = Number(
      tagPercentOccurrence.toFixed(2),
    );
  });

  const humanEyeMetrics = {
    accuracy: getAccuracy(humanEyePredictionOutcome as PredictionOutcomeType),
    precision: getPrecision(humanEyePredictionOutcome as PredictionOutcomeType),
    recall: getRecall(humanEyePredictionOutcome as PredictionOutcomeType),
    f1Score: getF1Score(humanEyePredictionOutcome as PredictionOutcomeType),
  };

  const C2PBehaviourMetrics = {
    accuracy: getAccuracy(
      C2PBehaviourPredictionOutcome as PredictionOutcomeType,
    ),
    precision: getPrecision(
      C2PBehaviourPredictionOutcome as PredictionOutcomeType,
    ),
    recall: getRecall(C2PBehaviourPredictionOutcome as PredictionOutcomeType),
    f1Score: getF1Score(C2PBehaviourPredictionOutcome as PredictionOutcomeType),
  };

  return {
    transactionSummary,
    crackedScreenTestMLModelResult,
    C2PBehaviourPredictionOutcome,
    humanEyePredictionOutcome,
    otherObservations,
    humanEyeMetrics,
    C2PBehaviourMetrics,
  };
};
