import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import style from "./TagList.module.scss";

type tagOptionsType = { value: string; label: string };
type Props = {
  tagOptions: tagOptionsType[];
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
};

export const TagList = ({ tagOptions, tags, setTags }: Props) => {
  return (
    <ToggleButtonGroup
      type="checkbox"
      value={tags}
      onChange={setTags}
      className={style.tagsButtonGroup}
    >
      {tagOptions.map((tag, index) => (
        <ToggleButton
          id={`tbg-btn-${index}`}
          key={index}
          value={tag.value}
          className={`${style.toggleButton} + ${tags.includes(tag.value) ? style.active : ""}`}
        >
          {tag.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
