import TRANSACTION_ICON from "../assets/icons/icon-transactions.svg";

interface LabelDefs {
  SINGLE_TRANSACTION: {
    BACK: string;
    TXN_LIST: string;
    SINGLE_TXN: string;
    OF: string;
    TXN_ID: string;
    METHOD: string;
    CREATED_ON: string;
    MAKE_AND_MODEL: string;
    CTA_CRACKED: string;
    CTA_NOT_CRACKED: string;
    CTA_PREVIOUS: string;
    CTA_NEXT: string;
    TAG_CONDITIONS: string;
    TAG_CONDITIONS_SUBTEXT: string;
    HUMAN_EYE_REVIEW: string;
    CRACKED_SCORE: string;
    WITH_REFLECTION: string;
    NO_REFLECTION: string;
    DEVICE_CONDITION: string;
    BEHAVIOR_EXPECTATION: string;
    BEHAVIOR_IS_DIFFERENT: string;
    COMMENTS: string;
    SUBMIT_BTN: string;
    SUBMIT_NOTIFICATION_TEXT: string;
    SUBMIT_AND_CONTINUE_BTN: string;
    REFLECTION_BOUNDING_BOXES: string;
    CRACK_PROBABILITY_WITH_REFLECTION: string;
    CRACK_PROBABILITY_WITHOUT_REFLECTION: string;
    BOUNDING_BOXES: string;
    GUIDELINE_HEADING: string;
    GUIDELINE_SUBHEADING: string;
    MODAL_CLOSE: string;
    GUIDE_TO_REVIEW_TXN: string;
    GUIDELINES: {
      GUIDELINE_1: string;
      GUIDELINE_2: string;
      GUIDELINE_3: string;
      GUIDELINE_4: string;
      GUIDELINE_5: string;
      GUIDELINE_6: string;
    };
  };
  TRANSACTION_LIST: {
    FILTERS: {
      TENANT: string;
      DATE_FROM: string;
      DATE_TO: string;
      CTA_GO: string;
      CTA_REPORT: string;
      CTA_GROUND_TRUTH: string;
      CTA_PERFORMANCE_REPORT: string;
      CTA_SUMMARY_REPORT: string;
      REPORT_BUTTON_TOOLTIP: string;
      VIEW_PERFORMANCE_REPORT: string;
      EXPORT_SUMMARY_REPORT: string;
    };
    TABLE_HEADINGS: {
      TXN_ID: string;
      DATE: string;
      DEVICE_DETAILS: string;
      TEST_NAME: string;
      TEST_METHOD: string;
      STATUS: string;
      RESULT: string;
      CLASSIFIER: string;
      RECLASSIFIED_PERFORMANCE: string;
    };
    NO_RECORD_FOUND: string;
  };
  HEADER: {
    TITLE: string;
  };
  SIDEBAR: {
    MENU_ITEMS: [{ TITLE: string; LOGO: string; PATH: string }];
  };
  PAGE_LOADER: { LOADING_TEXT: string };
  LOG_IN: {
    LOG_IN_TO: string;
    LOG_IN: string;
    USERNAME: string;
    PASSWORD: string;
    WRONG_CREDENTIALS_HEADING: string;
    WRONG_CREDENTIALS_DESC: string;
  };
  LOG_OUT: { TITLE: string };
  LOG_OUT_MODAL: { TITLE: string; SUB_TITLE: string; BUTTON: string };
  REPORT: {
    TITLE: string;
    SUBTITLE: string;
    SUBTITLE2: string;
    TRANSACTIONS_SUMMARY: {
      TOTAL_TRANSACTIONS: string;
      TRANSACTIONS_SUBMITTED: string;
      TRANSACTIONS_IN_PROGRESS: string;
      TRANSACTIONS_PENDING: string;
      ACCESS_DATA_PENDING: string;
      ACCESS_DATA_COMPLETED: string;
    };
    CRACKED_SCREEN_TEST_ML_MODEL_RESULT: {
      TITLE: string;
      TRANSACTIONS_ABOVE_THRESHOLD: string;
      TRANSACTIONS_BELOW_THRESHOLD: string;
    };
    HUMAN_EYE_PREDICTION_OUTCOME: {
      TITLE: string;
      TRUE_POSITIVE: string;
      TRUE_NEGATIVE: string;
      FALSE_POSITIVE: string;
      FALSE_NEGATIVE: string;
    };
    MANUAL_PREDICTION_OUTCOME: {
      TITLE: string;
      TRUE_POSITIVE: string;
      TRUE_NEGATIVE: string;
      FALSE_POSITIVE: string;
      FALSE_NEGATIVE: string;
    };
    OTHER_OBSERVATIONS: {
      TITLE: string;
      SHOW: string;
      ALL: string;
      FALSE_POSITIVE: string;
      FALSE_NEGATIVE: string;
      CATEGORISATION: string;
      OCCURRENCE: string;
      OVERALL_PERCENTAGE: string;
    };
    METRICS: {
      C2P_BEHAVIOUR_TITLE: string;
      HUMAN_EYE_PREDICTION_TITLE: string;
      ACCURACY: string;
      PRECISION: string;
      RECALL: string;
      F1_SCORE: string;
      CONFIDENCE_INTERVAL_TITLE:string;
      METRIC:string;
      PERFORMANCE:string;
      NINETY_FIVE_PERCENT_CI:string;
    };
  };
}

const ENGLISH: LabelDefs = {
  HEADER: {
    TITLE: "C2P Admin",
  },
  SIDEBAR: {
    MENU_ITEMS: [
      { TITLE: "Transactions", LOGO: TRANSACTION_ICON, PATH: "/transactions" },
    ],
  },
  LOG_IN: {
    LOG_IN: "Log in",
    USERNAME: "Username",
    PASSWORD: "Password",
    LOG_IN_TO: "Log in to",
    WRONG_CREDENTIALS_HEADING: "Wrong credentials",
    WRONG_CREDENTIALS_DESC: "Invalid username or password",
  },
  LOG_OUT: { TITLE: "Log out" },
  LOG_OUT_MODAL: {
    TITLE: "Session expired",
    SUB_TITLE: "Your login session has expired. Please log in again.",
    BUTTON: "Login",
  },
  PAGE_LOADER: {
    LOADING_TEXT: "Loading...",
  },
  SINGLE_TRANSACTION: {
    BACK: "<< Back to transactions page",
    TXN_LIST: "Transaction list",
    SINGLE_TXN: "Transaction",
    OF: "of",
    TXN_ID: "Transaction ID",
    METHOD: "Method",
    CREATED_ON: "Created on",
    MAKE_AND_MODEL: "Device info",
    CTA_CRACKED: "Cracked",
    CTA_NOT_CRACKED: "Not Cracked ",
    CTA_PREVIOUS: "< Previous",
    CTA_NEXT: "Next >",
    TAG_CONDITIONS: "Tag conditions",
    TAG_CONDITIONS_SUBTEXT: "Please select one or more conditions",
    HUMAN_EYE_REVIEW: "Human-eye review",
    CRACKED_SCORE: "Cracked probability score",
    WITH_REFLECTION: "With reflection",
    NO_REFLECTION: "No reflection",
    DEVICE_CONDITION: "Select device condition",
    BEHAVIOR_EXPECTATION: "C2P behaviour expectation",
    BEHAVIOR_IS_DIFFERENT: "is different",
    COMMENTS: "Comments",
    SUBMIT_BTN: "Submit",
    SUBMIT_NOTIFICATION_TEXT:
      "Transaction <>  has been successfully submitted.",
    SUBMIT_AND_CONTINUE_BTN: "Submit and Continue",
    BOUNDING_BOXES: "ODM Bounding boxes",
    REFLECTION_BOUNDING_BOXES: "Reflection bounding boxes",
    CRACK_PROBABILITY_WITH_REFLECTION: "Cracked probability with reflection:",
    CRACK_PROBABILITY_WITHOUT_REFLECTION:
      "Cracked probability without reflection:",
    GUIDELINE_HEADING: "Guidelines for manual interpretation",
    GUIDELINE_SUBHEADING: "Consider marking the transaction as cracked if",
    MODAL_CLOSE: "Close",
    GUIDE_TO_REVIEW_TXN: "Guide to review transactions",
    GUIDELINES: {
      GUIDELINE_1: "The screen has one or more <b>cracked lines</b>.",
      GUIDELINE_2: "<b>Dents</b> are present on the edges of the device.",
      GUIDELINE_3: "There is <b>pixel damage</b> on the screen.",
      GUIDELINE_4:
        "The screen protector of the device has been <b>damaged</b>, <b>unglued</b>, or <b>filled with air bubbles</b>.",
      GUIDELINE_5:
        "The image contains <b>dirty mirrors</b>, preventing manual interpretation.",
      GUIDELINE_6:
        "There are <b>clear reflections</b> in the image that resemble cracked lines.",
    },
  },
  TRANSACTION_LIST: {
    FILTERS: {
      TENANT: "Tenant",
      DATE_FROM: "Date From",
      DATE_TO: "Date To",
      CTA_GO: "Search",
      CTA_REPORT: "Generate Report",
      CTA_GROUND_TRUTH: "Ground Truth",
      CTA_PERFORMANCE_REPORT: "View Report",
      CTA_SUMMARY_REPORT: "Download Report",
      REPORT_BUTTON_TOOLTIP:
        "Button would be enabled only when classfier set for All Transactions for the duration selected",
      VIEW_PERFORMANCE_REPORT: "View Performance Report",
      EXPORT_SUMMARY_REPORT: "Export Summary Report",
    },
    TABLE_HEADINGS: {
      TXN_ID: "Transaction ID",
      DATE: "Date",
      DEVICE_DETAILS: "Device Details",
      TEST_NAME: "Test Name",
      TEST_METHOD: "Method",
      STATUS: "Status",
      RESULT: "Result",
      CLASSIFIER: "Classifier",
      RECLASSIFIED_PERFORMANCE: "Reclassified Performance",
    },
    NO_RECORD_FOUND:
      "There are no records to display for the selected date range.",
  },
  REPORT: {
    TITLE: "Report",
    SUBTITLE: "Based on ",
    SUBTITLE2: " classified images",
    TRANSACTIONS_SUMMARY: {
      ACCESS_DATA_PENDING: "% Access C2P ",
      ACCESS_DATA_COMPLETED: "% Completed C2P ",
      TOTAL_TRANSACTIONS: "Total #C2P links generated",
      TRANSACTIONS_SUBMITTED: "Completed",
      TRANSACTIONS_IN_PROGRESS: "In Progress",
      TRANSACTIONS_PENDING: "Not started",
    },
    CRACKED_SCREEN_TEST_ML_MODEL_RESULT: {
      TITLE: "Cracked Screen Test ML model result",
      TRANSACTIONS_ABOVE_THRESHOLD: "Predicted cracked device",
      TRANSACTIONS_BELOW_THRESHOLD: "Predicted uncracked device",
    },
    HUMAN_EYE_PREDICTION_OUTCOME: {
      TITLE: "Human-eye prediction outcome",
      TRUE_POSITIVE: "Correctly predicted cracked (TP)",
      FALSE_POSITIVE: "Wrongly predicted cracked (FP)",
      TRUE_NEGATIVE: "Correctly predicted uncracked (TN)",
      FALSE_NEGATIVE: "Wrongly predicted uncracked (FN)",
    },
    MANUAL_PREDICTION_OUTCOME: {
      TITLE: "C2P behaviour expectation",
      TRUE_POSITIVE: "Correctly predicted cracked (TP)",
      FALSE_POSITIVE: "Wrongly predicted cracked (FP)",
      TRUE_NEGATIVE: "Correctly predicted uncracked (TN)",
      FALSE_NEGATIVE: "Wrongly predicted uncracked (FN)",
    },
    OTHER_OBSERVATIONS: {
      TITLE: "Other observations on the submitted transactions",
      CATEGORISATION: "Categorisation",
      OCCURRENCE: "Occurrence",
      OVERALL_PERCENTAGE: "Overall percentage",
      SHOW: "Show",
      ALL: "All",
      FALSE_POSITIVE: "False positive",
      FALSE_NEGATIVE: "False negative",
    },
    METRICS: {
      C2P_BEHAVIOUR_TITLE: "C2P behaviour expectation performance metrics",
      HUMAN_EYE_PREDICTION_TITLE: "Human-eye performance metrics",
      ACCURACY: "Accuracy",
      PRECISION: "Precision",
      RECALL: "Recall",
      F1_SCORE: "F1 score",
      CONFIDENCE_INTERVAL_TITLE:"Confidence interval computed with 95% significance",
      METRIC:"Metric",
      PERFORMANCE:"Performance",
      NINETY_FIVE_PERCENT_CI:"95% Confidence Interval (ppts)"
    },
  },
};

export let LabelHelper = (lang = "ENGLISH") => {
  switch (lang) {
    case "ENGLISH":
      return ENGLISH;

    default:
      return ENGLISH;
  }
};

// export const LabelHelper = {
//   ENGLISH: ENGLISH,
// };
