export const C2P_TESTS = {
  FRONT_SCREEN_CRACK_TEST: {
    name: "is-front-screen-cracked",
  },
};

export const DEFAULT_CLASSIFIER_THRESHOLD_VALUE = 0.5;

export const OTHER_TAGS: { [id: string]: string } = {
  ROOM_REFLECTION: "Reflection of room",
  REFLECTION_OF_USER: "Reflection of user",
  FINGERS_ON_SCREEN: "Fingers on screen",
  SCREEN_CUT: "Screen cut",
  BLURRED_IMAGE: "Blurred image",
  DIRTY_MIRROR: "Dirty mirror",
  PII: "PII",
  IMAGE_ON_SCREEN: "Image on screen",
  NOT_A_PHONE: "Not a phone",
  DULL: "Dull",
  CROPPED: "Cropped",
  FLIP_OR_FOLD: "Flip/Fold",
  PIXEL: "Pixel",
  FRAUD: "Fraud",
  SCREEN_PROTECTOR_DAMAGED: "Screen protector damaged (and wrong prediction)",
  OTHER_COMMENTS: "Other comments",
};

export const ML_CLASSIFICATION_TERMS = {
  TRUE_POSITIVE: "TP",
  TRUE_NEGATIVE: "TN",
  FALSE_POSITIVE: "FP",
  FALSE_NEGATIVE: "FN",
};

export const CLASSIFIER_PREDICTIONS_DIGITS_AFTER_DECIMAL = 5;

export const TEST_RESULT_CRACK_PROBABILITY_DIGITS_AFTER_DECIMAL = 3;

export const ALL_TENANTS = "ALL_TENANTS";

export const API_RESPONSE_SUCCESS = "success";

export const VIEW = "VIEW";

export const DOWNLOAD = "DOWNLOAD";
