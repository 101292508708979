import { useState } from "react";
import { Navigate } from "react-router-dom";
import C2PButton from "components/UI/C2Pbutton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Style from "./LoginPage.module.scss";
import { LabelHelper } from "helpers/labelHelper";
import Alert from "react-bootstrap/Alert";
import { useAuth } from "hooks/useAuth";
import { isSessionExpired } from "helpers/authHelper";

export const LoginPage = ({ lang }: { lang: string }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const isLoginDisabled = !username.length || !password.length;

  const { handleOnLogin, isError, isLoading } = useAuth();

  if (!isSessionExpired()) {
    return <Navigate to="/transactions" />;
  }

  return (
    <div className={Style.loginWrapper}>
      <div className={Style.loginWrapperInner}>
        <Form>
          <h3>{`${LabelHelper(lang).LOG_IN.LOG_IN_TO} ${
            LabelHelper(lang).HEADER.TITLE
          }`}</h3>

          <Alert variant="danger" className={Style.alert} show={isError}>
            <div className={Style.alertHeading}>
              {LabelHelper(lang).LOG_IN.WRONG_CREDENTIALS_HEADING}
            </div>
            <div>{LabelHelper(lang).LOG_IN.WRONG_CREDENTIALS_DESC}</div>
          </Alert>

          <Form.Group className="mb-3" controlId="email">
            <InputGroup>
              <InputGroup.Text id="user-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
              </InputGroup.Text>
              <Form.Control
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder={LabelHelper(lang).LOG_IN.USERNAME}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-5" controlId="password">
            <InputGroup>
              <InputGroup.Text id="user-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-lock-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                </svg>
              </InputGroup.Text>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={LabelHelper(lang).LOG_IN.PASSWORD}
              />
            </InputGroup>
          </Form.Group>

          <div className="d-grid">
            <C2PButton
              text={
                isLoading
                  ? `${LabelHelper(lang).LOG_IN.LOG_IN} ...`
                  : LabelHelper(lang).LOG_IN.LOG_IN
              }
              propStyle={{ padding: "12px 60px" }}
              disabled={isLoginDisabled || isLoading}
              onClick={() => handleOnLogin({ username, password })}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};
