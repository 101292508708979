import {
  selectNextTransaction,
  selectPreviousTransaction,
  selectTransaction,
} from "store/transactionsSlice";
import { useAppSelector, useAppDispatch } from "./hooks";
import { useNavigate } from "react-router-dom";

export const useTransactionNavigator = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const selectedTransaction = useAppSelector(
    (state) => state.transactions.selectedTransaction,
  );
  const transactions = useAppSelector(
    (state) => state.transactions.submittedTransactions,
  );

  const { transactionId } = selectedTransaction;

  const totalTransactions = transactions.length;

  const currentIndex = transactions.findIndex(
    (record) => record.transactionId === transactionId,
  );
  const recordNumber = currentIndex + 1;

  const hasNoNextRecord = recordNumber === totalTransactions;
  const hasNoPreviousRecord = recordNumber === 1;

  const nextRecordTransactionId = hasNoNextRecord
    ? ""
    : transactions[currentIndex + 1].transactionId;

  const previousRecordTransactionId = hasNoPreviousRecord
    ? ""
    : transactions[currentIndex - 1]?.transactionId;

  const onNext = () => {
    dispatch(selectNextTransaction(currentIndex));
    navigate(`/transaction/${nextRecordTransactionId}`);
  };

  const onPrevious = () => {
    dispatch(selectPreviousTransaction(currentIndex));
    navigate(`/transaction/${previousRecordTransactionId}`);
  };

  const selectTransactionById = (id: string) => {
    const transactionRecord = transactions.find(
      (rec) => rec.transactionId === id,
    );
    transactionRecord && dispatch(selectTransaction(transactionRecord));
  };

  return {
    recordNumber,
    onPrevious,
    onNext,
    hasNoNextRecord,
    hasNoPreviousRecord,
    totalTransactions,
    selectTransactionById,
  };
};
