import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const A4_DIMENSIONS = {
  width: 210,
  height: 295,
};

export const downloadPdfDocument = (reportName: string) => {
  const reportPdf = new jsPDF({ format: "a4" });

  // GENERIC DATA PAGE
  html2canvas(document.getElementById("genericReportData") as HTMLElement).then(
    (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = A4_DIMENSIONS.width;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let position = 0;

      reportPdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    },
  );

  // HUMAN EYE PREDICTION REPORT
  humanEyePredictionReportData(reportPdf, reportName);
};

const humanEyePredictionReportData = (reportPdf: jsPDF, reportName: string) => {
  html2canvas(
    document.getElementById("humanEyePredictionReportData") as HTMLElement,
  ).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pageHeight = A4_DIMENSIONS.height;
    const imgWidth = A4_DIMENSIONS.width;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    var remainingHeight = imgHeight;
    let position = 10;

    reportPdf.addPage();
    reportPdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    remainingHeight -= pageHeight;

    while (remainingHeight >= 0) {
      position += remainingHeight - imgHeight; // top padding for other pages
      reportPdf.addPage();
      reportPdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      remainingHeight -= pageHeight;
    }

    // MANUAL PREDICTION OUTCOME REPORT
    manualPredictionOutcomeReportData(reportPdf, reportName);
  });
}

const manualPredictionOutcomeReportData = (reportPdf: jsPDF, reportName: string) => {
  html2canvas(
    document.getElementById("manualPredictionOutcomeReportData") as HTMLElement,
  ).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = A4_DIMENSIONS.width;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let position = 10;

    reportPdf.addPage();
    reportPdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);

    reportPdf.save(reportName);
  });
}

export const downloadDocument = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
