import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ToastNotificationType } from "helpers/commonComponents.types";

// Define a type for the slice state
interface CommonState {
    toastNotification: ToastNotificationType;
}

// Define the initial state using that type
const initialState: CommonState = {
    toastNotification: {
        show: false,
        description: "",
        autoHide: 3000,
        position: "top-center",
        icon: "",
        dismissible: true,
    }
};

export const commonSlice = createSlice({
    name: "commonComponents",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setToastNotification: (state, action: PayloadAction<ToastNotificationType>) => {
            state.toastNotification = action.payload;
        }
    }
});

export const { setToastNotification } = commonSlice.actions;

export default commonSlice.reducer;
