import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import style from "./transactionsListPage.module.scss";
import FormSelectBox from "components/UI/formSelectBox";
import C2Pbutton from "components/UI/C2Pbutton";
import { PageLoader } from "components/UI/page-loader";
import { TransactionsTable } from "./components/TransactionsTable";
import { useAppSelector, useAppDispatch } from "../../hooks/hooks";
import {
  fetchTransactions,
  setFilters,
  selectTransaction,
  setLoadingStatus,
} from "../../store/transactionsSlice";
import { LabelHelper } from "helpers/labelHelper";
import CALENDAR_ICON from "../../assets/icons/date-calendar.svg";
import {
  dateToYMD,
  getTenantList,
  getAdjustedDate,
} from "../../helpers/transactionsHelper";
import {
  ALL_TENANTS,
  API_RESPONSE_SUCCESS,
  VIEW,
  DOWNLOAD,
} from "models/constants";
import { getSummaryReport } from "../../services/api/report";
import { downloadDocument } from "../../helpers/reportDownloadHelper";
import { ReactSVG } from "react-svg";
import { setToastNotification } from "store/commonSlice";

type Props = {
  lang: string;
};

const DATE_FORMAT = "dd/MM/yyyy";
const DATE_TO = "TO";
const DATE_FROM = "FROM";

export default function TransactionsListPage({ lang }: Props) {
  const [isDirty, setIsDirty] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loadingStatus = useAppSelector((state) => state.transactions.status);
  const filters = useAppSelector((state) => state.transactions.filters);
  const transactionsList = useAppSelector(
    (state) => state.transactions.submittedTransactions,
  );
  const toastNotification = useAppSelector(
    (state) => state.commonComponents.toastNotification
  );

  const loadTransactions = async () => {
    let params = {
      tenantCode: filters.tenantCode || "",
      startDate: dateToYMD(filters.startDate),
      endDate: dateToYMD(filters.endDate),
    };

    if (filters.tenantCode === ALL_TENANTS) {
      params = { ...params, ...{ notReport: true } };
    }
    try {
      await dispatch(fetchTransactions(params)).unwrap();
      setIsDirty(false);
    } catch (error) {
      dispatch(setToastNotification({
        ...toastNotification,
        show: toastNotification.show ? false : true,
        description: error as string,
      }))
    }
  };

  const handleTenantChange = (option: any) => {
    setIsDirty(true);
    dispatch(setFilters({ ...filters, tenantCode: option.value }));
  };

  const handleOnReportClick = () => navigate("/transactions/report");

  const handleOnSummaryReportClick = async () => {
    dispatch(setLoadingStatus("loading"));
    try {
      const result = await getSummaryReport({
        tenantCode: filters.tenantCode || "",
        startDate: dateToYMD(filters.startDate),
        endDate: dateToYMD(filters.endDate),
      });

      dispatch(setLoadingStatus("succeeded"));
      if (
        result?.data?.message === API_RESPONSE_SUCCESS &&
        result.data.signedUrl
      ) {
        downloadDocument(result.data.signedUrl);
      }
    } catch (error: any) {
      dispatch(setLoadingStatus("failed"));
      alert(error?.message);
    }
  };

  const openDatePickerFn = (value: string) => {
    if (openDatePicker === value) {
      setOpenDatePicker('')
    }
    else {
      setOpenDatePicker(value)
    }
  }

  const isActionDisabled = !transactionsList.length || isDirty;

  return (
    <>
      <div className="container-fluid py-4">
        <div className={style.filterContainer}>
          <div className="row">
            <div className="col-md-3">
              <div className={style.tenantDropDown + " form-group"}>
                <label className={style.filterLabel} htmlFor="tenant">
                  {LabelHelper(lang).TRANSACTION_LIST.FILTERS.TENANT}
                </label>
                <FormSelectBox
                  name="tenant"
                  isMulti={false}
                  options={getTenantList()}
                  onChange={handleTenantChange}
                  variant={"primary"}
                  propStyles={{                
                    background: "white"
                  }}
                  value={
                    filters.tenantCode.length
                      ? {
                        label: filters.tenantCode,
                        value: filters.tenantCode,
                      }
                      : undefined
                  }
                />
              </div>
            </div>

            <div className={style.dateContainer + " col-md-2"}>
              <div className="form-group position-relative">
                <label className={style.filterLabel} htmlFor="tenant">
                  {LabelHelper(lang).TRANSACTION_LIST.FILTERS.DATE_FROM}
                </label>
                <DatePicker
                  wrapperClassName={style.datePickerCustomStyle}
                  dateFormat={DATE_FORMAT}
                  closeOnScroll={true}
                  minDate={getAdjustedDate(90, new Date(filters?.endDate))}
                  selected={filters.startDate}
                  onChange={(date: any) => {
                    setIsDirty(true);
                    dispatch(setFilters({ ...filters, startDate: date }));
                    setOpenDatePicker("")
                  }}
                  open={openDatePicker === DATE_FROM}
                  onFocus={() => openDatePickerFn(DATE_FROM)}
                  onClickOutside={() => setOpenDatePicker("")}
                />
                <div className={style.dateIconContainer}>
                  <ReactSVG
                    onClick={() => openDatePickerFn(DATE_FROM)}
                    src={CALENDAR_ICON}
                  />
                </div>
              </div>
            </div>

            <div className={style.dateContainer + " col-md-2"}>
              <div className={style.dateTo + " form-group position-relative"}>
                <label className={style.filterLabel} htmlFor="tenant">
                  {LabelHelper(lang).TRANSACTION_LIST.FILTERS.DATE_TO}
                </label>
                <DatePicker
                  dateFormat={DATE_FORMAT}
                  closeOnScroll={true}
                  maxDate={new Date()}
                  selected={filters.endDate}
                  wrapperClassName={style.datePickerCustomStyle}
                  onChange={(date: any) => {
                    setIsDirty(true);
                    dispatch(setFilters({ ...filters, endDate: date }));
                    setOpenDatePicker("")
                  }}
                  open={openDatePicker === DATE_TO}
                  onFocus={() => openDatePickerFn(DATE_TO)}
                  onClickOutside={() => setOpenDatePicker("")}
                />
                <div className={style.dateIconContainer}>
                  <ReactSVG
                    onClick={() => openDatePickerFn(DATE_TO)}
                    src={CALENDAR_ICON}
                  />
                </div>
              </div>
            </div>

            <div className={style.buttonContainer + " col-md-5"}>
              <C2Pbutton
                propStyle={{
                  padding: "12px 20px",
                }}
                text={LabelHelper(lang).TRANSACTION_LIST.FILTERS.CTA_GO}
                onClick={loadTransactions}
                disabled={!filters.tenantCode.length}
              />
              {filters?.tenantCode !== ALL_TENANTS ? (
                <div
                  title={
                    LabelHelper(lang).TRANSACTION_LIST.FILTERS
                      .REPORT_BUTTON_TOOLTIP
                  }
                >
                  <C2Pbutton
                    propStyle={{
                      padding: "12px 14px",
                    }}
                    text={
                      LabelHelper(lang).TRANSACTION_LIST.FILTERS
                        .CTA_PERFORMANCE_REPORT
                    }
                    onClick={handleOnReportClick}
                    disabled={isActionDisabled}
                    variant={"secondary"}
                  />
                </div>
              ) : (
                <div className={style.reportSelectContainer}>
                  <FormSelectBox
                    placeholder="Reports"
                    name="reports"
                    isMulti={false}
                    options={[
                      {
                        value: VIEW,
                        label:
                          LabelHelper(lang).TRANSACTION_LIST.FILTERS
                            .VIEW_PERFORMANCE_REPORT,
                      },
                      {
                        value: DOWNLOAD,
                        label:
                          LabelHelper(lang).TRANSACTION_LIST.FILTERS
                            .EXPORT_SUMMARY_REPORT,
                      },
                    ]}
                    onChange={(option: any) => {
                      if (option.value === VIEW) {
                        handleOnReportClick();
                      } else {
                        handleOnSummaryReportClick();
                      }
                    }}
                    disabled={isActionDisabled}
                    variant={"secondary"}
                    isSearchable={false}
                    propStyles={{
                      background: "transparent"
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={style.dataTable + " pt-5"}>
          <TransactionsTable lang={lang} />
        </div>
      </div>

      <PageLoader show={loadingStatus === "loading"} lang={lang} />
    </>
  );
}
