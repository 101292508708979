import Style from "../GroundTruthReportPage.module.scss";
import ArrowDown from "assets/icons/arrow-down.svg";
import ArrowUp from "assets/icons/arrow-up.svg";
import { LabelHelper } from "helpers/labelHelper";
import { CrackedScreenTestMLModelResultType } from "helpers/transaction.types";

type Props = {
  lang: string;
  crackedScreenTestMLModelResult: CrackedScreenTestMLModelResultType;
};

export const CrackedScreenTestMLModelResult = ({
  lang,
  crackedScreenTestMLModelResult,
}: Props) => {
  const { aboveThreshold, belowThreshold } = crackedScreenTestMLModelResult;

  return (
    <>
      <div className={Style.sectionHeading}>
        {LabelHelper(lang).REPORT.CRACKED_SCREEN_TEST_ML_MODEL_RESULT.TITLE}
      </div>

      <div className={Style.dataRow}>
        <div>
          <img src={ArrowUp} alt="arrow up" />
          <span className={Style.heading}>
            {
              LabelHelper(lang).REPORT.CRACKED_SCREEN_TEST_ML_MODEL_RESULT
                .TRANSACTIONS_ABOVE_THRESHOLD
            }
          </span>
          <div className={Style.content}>{aboveThreshold}</div>
        </div>

        <div>
          <img src={ArrowDown} alt="arrow down" />
          <span className={Style.heading}>
            {
              LabelHelper(lang).REPORT.CRACKED_SCREEN_TEST_ML_MODEL_RESULT
                .TRANSACTIONS_BELOW_THRESHOLD
            }
          </span>
          <div className={Style.content}>{belowThreshold}</div>
        </div>
      </div>
    </>
  );
};
