import React, { useEffect, useMemo, useState } from "react";
import { ImageDataType, TestType } from "helpers/transaction.types";

const GREEN = "green";
const BLUE = "blue";
const ORANGE = "orange";

type Props = {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  canvasReflectionRef: React.RefObject<HTMLCanvasElement>;
  test: TestType;
  isBoundingBoxEnabled: boolean;
  isReflectionBoundingBoxEnabled: boolean;
};

export const useODMPredictions = ({
  canvasRef,
  canvasReflectionRef,
  test,
  isBoundingBoxEnabled,
  isReflectionBoundingBoxEnabled,
}: Props) => {
  const [imageData, setImageData] = useState<ImageDataType>(
    {} as ImageDataType,
  );
  const canvas = canvasRef.current as unknown as HTMLCanvasElement;
  const reflectionCanvas =
    canvasReflectionRef.current as unknown as HTMLCanvasElement;

  const odmPredictionBoxes = useMemo(
    () => test?.results?.odmPrediction?.boxes || [],
    [test?.results?.odmPrediction?.boxes],
  );
  const odmPredictionLabels = useMemo(
    () => test?.results?.odmPrediction?.labels || [],
    [test?.results?.odmPrediction?.labels],
  );
  const odmPredictionScores = useMemo(
    () => test?.results?.odmPrediction?.scores || [],
    [test?.results?.odmPrediction?.scores],
  );

  const deviceBoundingBox = useMemo(
    () => test?.results?.odmPrediction?.deviceBoundingBox || [],
    [test?.results?.odmPrediction?.deviceBoundingBox],
  );

  const reflectionLabels = useMemo(
    () => test?.results?.odmPrediction?.reflectionLabels || odmPredictionLabels,
    [test?.results?.odmPrediction?.reflectionLabels],
  );

  const reflectionScore = useMemo(
    () => test?.results?.odmPrediction?.reflectionScore || odmPredictionScores,
    [test?.results?.odmPrediction?.reflectionScore],
  );

  const reflectionScoreTemp = [...reflectionScore];
  const reflectionScoresTemp = [...reflectionScore];

  // take out score, index and label for all green bounding boxes
  const odmScoresForGreenLabelIndexMap = odmPredictionScores
    .map((score, index) => ({
      score,
      index,
      label: odmPredictionLabels[index],
    }))
    .filter((obj) => obj.label === 1);

  //get the highest 3 odm score green boxes
  odmScoresForGreenLabelIndexMap.sort((a, b) => b.score - a.score).splice(3);

  // take out score, index and label for all orange bounding boxes with label 3
  const odmScoresForOrangeLabelIndexMap = reflectionScoresTemp
    .map((score, index) => ({
      score,
      index,
      label: reflectionLabels[index],
    }))
    .filter((obj) => obj.label === 3);

  //get the highest 3 odm score orange boxes
  odmScoresForOrangeLabelIndexMap.sort((a, b) => b.score - a.score).splice(3);

  const onImageLoad = (imageElement: HTMLImageElement | undefined) => {
    const currentWidth = imageElement?.clientWidth || 0;
    const currentHeight = imageElement?.clientHeight || 0;
    const realWidth = imageElement?.naturalWidth || 0;
    const realHeight = imageElement?.naturalHeight || 0;

    setImageData({
      currentWidth,
      currentHeight,
      realHeight,
      realWidth,
    });
  };

  useEffect(() => {
    // plot ODM Prediction boxes on device image
    const { currentWidth, currentHeight, realHeight, realWidth } = imageData;

    const xZoomFactor = currentWidth / realWidth;
    const yZoomFactor = currentHeight / realHeight;

    const formatScore = (score: number) => score?.toFixed(2)?.toString();

    // get the deviceBoundingBox coordinates
    const [X1, Y1, X2, Y2] = deviceBoundingBox;

    const drawBoundingBoxes = (
      box: number[],
      context: CanvasRenderingContext2D,
      color: string,
      score: string,
    ) => {
      let x1, y1, x2, y2;

      if (color === BLUE) {
        x1 = X1;
        x2 = X2;
        y1 = Y1;
        y2 = Y2;
      } else {
        [x1, y1, x2, y2] = box;

        x1 = x1 + X1;
        x2 = x2 + X1;
        y1 = y1 + Y1;
        y2 = y2 + Y1;
      }

      // we are using a zoomFactor to plot respective bounding box in proportion to original image on our
      // current device image
      x1 = x1 * xZoomFactor;
      x2 = x2 * xZoomFactor;
      y1 = y1 * yZoomFactor;
      y2 = y2 * yZoomFactor;

      const width = x2 - x1;
      const height = y2 - y1;

      context.beginPath();
      context.lineWidth = 2;
      context.strokeStyle = color;
      context.rect(x1, y1, width, height);

      context.fillStyle = color;
      const xCoordinateForScoreBox = color === ORANGE ? x1+width-22 : x1;
      context.fillRect(xCoordinateForScoreBox, y1, 22, 14);

      context.textAlign = color === ORANGE? "right": "left";
      context.fillStyle = "white";

      const xCoordinateForScoreText =  color === ORANGE? x1 + width : x1 + 2;
      context.fillText(score, xCoordinateForScoreText, y1+10);
      context.stroke();
    };

    if (canvas) {
      const context = canvas.getContext("2d");

      if (context) {
        // clear canvas before plotting new bounding boxes
        context.clearRect(0, 0, canvas.width, canvas.height);
        if (odmPredictionBoxes.length && isBoundingBoxEnabled) {
          odmPredictionBoxes.forEach((box, index) => {
            // blue for device container bounding box, green for crack containing bounding boxes C2P-1999
            // odmPredictionLabels array contains corresponding values for each box in odmPredictionBoxes
            // odmPredictionLabels array contains either 1 or 2 or 3 as values, 2 for device container and 1 for crack container
            const color = odmPredictionLabels[index] === 1 ? GREEN : BLUE;
            let score;

            const currentBoxInTop3Score = odmScoresForGreenLabelIndexMap.find(
              (scoreObj) => scoreObj.index === index,
            )?.score;

            score = formatScore(odmPredictionScores[index]);

            if (color === BLUE || currentBoxInTop3Score) {
              drawBoundingBoxes(box, context, color, score as string);
            }
          });
        }
      }
    }
    if (reflectionCanvas) {
      const context = reflectionCanvas.getContext("2d");
      if (context) {
        context.clearRect(0, 0, canvas.width, canvas.height);

        if (odmPredictionBoxes.length && isReflectionBoundingBoxEnabled) {
          odmPredictionBoxes.forEach((box, index) => {
            // orange for reflection bounding box, C2P-2255
            const color = ORANGE;
            let score;

            const currentBoxInTop3Score = odmScoresForOrangeLabelIndexMap.find(
              (scoreObj) => scoreObj.index === index,
            )?.score;
            score = formatScore(reflectionScore[index]);

            if (currentBoxInTop3Score) {
              drawBoundingBoxes(box, context, color, score as string);
            }
          });
        }
      }
    }
  }, [
    odmScoresForGreenLabelIndexMap,
    odmPredictionBoxes,
    canvas,
    imageData,
    isBoundingBoxEnabled,
    isReflectionBoundingBoxEnabled,
    deviceBoundingBox,
  ]);

  return { onImageLoad, imageData };
};
