import BootstrapTable from "react-bootstrap-table-next";
import { useAppSelector } from "hooks/hooks";
import {
  getFrontScreenCrackedTest,
  getTxnCreatedDateTime,
  getProcessedTestData,
  getMakeAndModel,
} from "helpers/transactionsHelper";
import moment from "moment";
import { Txn } from "helpers/transaction.types";
import { Link } from "react-router-dom";
import { useAppDispatch } from "hooks/hooks";
import { selectTransaction } from "store/transactionsSlice";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { LabelHelper } from "helpers/labelHelper";
import style from "./transactionsTable.module.scss";
import ICON_FALSE_NEGATIVE from "assets/icons/icon-false-negative.svg";
import ICON_FALSE_POSITIVE from "assets/icons/icon-false-positive.svg";
import ICON_TRUE_NEGATIVE from "assets/icons/icon-true-negative.svg";
import ICON_TRUE_POSITIVE from "assets/icons/icon-true-positive.svg";
import { ReactSVG } from "react-svg";

interface Props {
  lang: string;
}

export const TransactionsTable = ({ lang }: Props) => {
  const transactionsList = useAppSelector(
    (state) => state.transactions.submittedTransactions,
  );

  const tenantCode = useAppSelector(
    (state) => state.transactions.filters.tenantCode,
  );

  const dispatch = useAppDispatch();

  const columns = [
    {
      dataField: "transactionId",
      text: LabelHelper(lang).TRANSACTION_LIST.TABLE_HEADINGS.TXN_ID,
      formatter: (_transactionId: string, txn: Txn) => (
        <Link
          to={`/transaction/${_transactionId}`}
          onClick={() => dispatch(selectTransaction(txn))}
        >
          {txn.transactionId}
        </Link>
      ),
      headerStyle: () => {
        return { 
          width: "20%" 
        };
      },
    },
    {
      dataField: "createdAt",
      text: LabelHelper(lang).TRANSACTION_LIST.TABLE_HEADINGS.DATE,
      sort: true,
      formatter: (date: any, txn: Txn) => {
        const createdAt = getTxnCreatedDateTime(txn);
        return <>{createdAt}</>;
      },
      sortFunc: (a: Date, b: Date, order: string) => {
        if (order === "asc") {
          return moment(b).isBefore(moment(a)) ? 1 : -1;
        }
        return moment(b).isAfter(moment(a)) ? 1 : -1;
      }
    },
    {
      dataField: "makeAndModel",
      text: LabelHelper(lang).TRANSACTION_LIST.TABLE_HEADINGS.DEVICE_DETAILS,
      formatter: getMakeAndModel,
    },
    {
      dataField: "testMethod",
      text: LabelHelper(lang).TRANSACTION_LIST.TABLE_HEADINGS.TEST_METHOD,
      sort: true
    },
    {
      dataField: "testStatus",
      text: LabelHelper(lang).TRANSACTION_LIST.TABLE_HEADINGS.STATUS,
      formatter: (status: string, txn: Txn) => (
        <span className={style.trxnStatusPill}>{status}</span>
      ),
    },
    {
      dataField: "result",
      text: LabelHelper(lang).TRANSACTION_LIST.TABLE_HEADINGS.RESULT,
      sort: true,
    },
    {
      dataField: "classifier",
      text: LabelHelper(lang).TRANSACTION_LIST.TABLE_HEADINGS.CLASSIFIER,
      sort: true,
      formatter: (value: any, txn: Txn) => {
        return (
          <div className="d-flex">
            {getClassifierIcon(value)} {value}
          </div>
        );
      },
    },
    {
      dataField: "reclassifiedPerformance",
      text: LabelHelper(lang).TRANSACTION_LIST.TABLE_HEADINGS
        .RECLASSIFIED_PERFORMANCE,
      sort: true,
      formatter: (value: any, txn: Txn) => {        
        return (
          <div className="d-flex">
            {getClassifierIcon(value)} {value}
          </div>
        );
      },
    },
  ];

  const getClassifierIcon = (value: string) => {
    switch (value) {
      case "TP":
        return <ReactSVG className="me-2" src={ICON_TRUE_POSITIVE} />;
      case "TN":
        return <ReactSVG className="me-2" src={ICON_TRUE_NEGATIVE} />;
      case "FP":
        return <ReactSVG className="me-2" src={ICON_FALSE_POSITIVE} />;
      case "FN":
        return <ReactSVG className="me-2" src={ICON_FALSE_NEGATIVE} />;

      default:
        break;
    }
  };

  return (
    <>
      <div className={style.trxnListTable}>
        <BootstrapTable
          keyField="transactionId"
          data={transactionsList.map((txn) => {
            const test = getFrontScreenCrackedTest(txn);
            const { name, status, result, classifier, reclassifiedPerformance, testMethod } = getProcessedTestData(
              test,
              tenantCode,
            );

            return {
              ...txn,
              classifier,
              reclassifiedPerformance,
              testName: name,
              testStatus: status,
              result,
              testMethod
            };
          })}
          columns={columns}
          bordered={false}
        />
      </div>

      {transactionsList.length === 0 && (
        <p className={style.noDataFound}>{LabelHelper(lang).TRANSACTION_LIST.NO_RECORD_FOUND}</p>
      )}
    </>
  );
};
