import Table from "react-bootstrap/Table";
import Style from "../GroundTruthReportPage.module.scss";
import { LabelHelper } from "helpers/labelHelper";
import { MetricsType } from "helpers/transaction.types";

type Props = {
  lang: string;
  metrics: MetricsType;
  title: string;
};

export const Metrics = ({ lang, metrics, title }: Props) => {
  const { accuracy, precision, recall, f1Score } = metrics;

  return (
    <>
      <div className={Style.sectionContainer}>
        <div className={Style.sectionHeading}>{title}</div>
        <Table className="table table-borderless">
        <thead>
            <tr>
            <th>{LabelHelper(lang).REPORT.METRICS.METRIC}</th>
            <th>{LabelHelper(lang).REPORT.METRICS.PERFORMANCE}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{LabelHelper(lang).REPORT.METRICS.ACCURACY}</td>
              <td>
                <progress value={accuracy} max="100" />
                <span>{accuracy ? accuracy.toFixed(2) : accuracy}%</span>
              </td>
            </tr>
            <tr>
              <td>{LabelHelper(lang).REPORT.METRICS.PRECISION}</td>
              <td>
                <progress value={precision} max="100" />
                <span>{precision ? precision.toFixed(2) : precision}%</span>
              </td>
            </tr>
            <tr>
              <td>{LabelHelper(lang).REPORT.METRICS.RECALL}</td>
              <td>
                <progress value={recall} max="100" />
                <span>{recall ? recall.toFixed(2) : recall}%</span>
              </td>
            </tr>
            <tr>
              <td>{LabelHelper(lang).REPORT.METRICS.F1_SCORE}</td>
              <td>
                <progress value={f1Score} max="100" />
                <span>{f1Score ? f1Score.toFixed(2) : f1Score}%</span>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};
