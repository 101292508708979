import Modal from "react-bootstrap/Modal";
import C2Pbutton from "components/UI/C2Pbutton";
import { LabelHelper } from "helpers/labelHelper";

type Props = {
  show: boolean;
  handleClose: () => void;
  lang: string;
};

export const SessionExpiryModal = ({ show, handleClose, lang }: Props) => (
  <Modal show={show} onHide={handleClose} fullscreen="md">
    <Modal.Header>
      <Modal.Title className="py-3 px-2">
        {LabelHelper(lang).LOG_OUT_MODAL.TITLE}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <div className="px-2 pt-3 pb-4">
        {LabelHelper(lang).LOG_OUT_MODAL.SUB_TITLE}
      </div>
    </Modal.Body>
    <Modal.Footer className="justify-content-center">
      <C2Pbutton
        propStyle={{
          padding: "14px 24px",
          borderRadius: 0,
          marginTop: "24px",
        }}
        onClick={handleClose}
        text={LabelHelper(lang).LOG_OUT_MODAL.BUTTON}
      />
    </Modal.Footer>
  </Modal>
);
