import style from "./header.module.scss";
import { LabelHelper } from "helpers/labelHelper";
import { useAuth } from "hooks/useAuth";
import { ReactSVG } from "react-svg";
import LOGOUT_ICON from "assets/icons/icon-logout.svg";

type Props = { lang: string };

const Header = ({ lang }: Props) => {
  const { handleOnLogout } = useAuth();

  return (
    <header className={style.headerContainer + " py-3 px-3"}>
      <div className="container-fluid">
        <div
          className={style.iconContainer}
          title={LabelHelper(lang).LOG_OUT.TITLE}
        >
          <h4 onClick={handleOnLogout} className="text-end d-flex justify-content-end">
            <ReactSVG className="me-2" src={LOGOUT_ICON} />
            {LabelHelper(lang).LOG_OUT.TITLE}
          </h4>
        </div>
      </div>
    </header>
  );
};

export default Header;
