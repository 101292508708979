import { useRef, useState } from "react";
import { ClassifierPredictionsType, TestType } from "helpers/transaction.types";
import ReactImageMagnify from "react-image-magnify";
import Spinner from "react-bootstrap/Spinner";
import style from "./DeviceImage.module.scss";
import { useODMPredictions } from "hooks/useODMPredictions";

type Props = {
  classifierPredictions: ClassifierPredictionsType;
  imageUrl: string;
  transactionId: string;
  test: TestType;
  isBoundingBoxEnabled: boolean;
  isReflectionBoundingBoxEnabled: boolean;
};

export const DeviceImage = ({
  classifierPredictions,
  imageUrl,
  transactionId,
  test,
  isBoundingBoxEnabled,
  isReflectionBoundingBoxEnabled,
}: Props) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  const canvasRef = useRef(null);
  const canvasReflectionRef = useRef(null);

  const { onImageLoad, imageData } = useODMPredictions({
    canvasRef,
    canvasReflectionRef,
    test,
    isBoundingBoxEnabled,
    isReflectionBoundingBoxEnabled,
  });

  const { currentHeight, currentWidth } = imageData;

  const { topLeft, topRight, bottomLeft, bottomRight } = classifierPredictions;

  const onCanvasClick = () => {
    const newWindow = window.open(
      imageUrl,
      "imageWindow",
      "noopener,noreferrer",
    );
    if (newWindow) newWindow.opener = null;
  };

  const setImage = (imageID: string) => {
    const imageElement = document
      .getElementById(imageID)
      ?.getElementsByTagName("img")[0];
    onImageLoad(imageElement);
  };

  return (
    <div className={style.deviceImageSection + " position-relative pt-3"}>
      {isImageLoading ? (
        <div className={style.spinner}>
          <Spinner animation="border" />
        </div>
      ) : null}

      <div className={"mb-1 d-flex justify-content-between"}>
        <h5 className="mb-0">{topLeft}</h5>
        <h5 className="mb-0">{topRight}</h5>
      </div>

      <div
        id={`image-${transactionId}`}
        onClick={() => window.open(imageUrl, "_blank")}
        className={style.deviceImageContainer + " position-relative"}
      >
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: "UPLOADED DEVICE IMAGE",
              isFluidWidth: true,
              src: imageUrl,
              onLoad: () => {
                setImage(`image-${transactionId}`);
                setIsImageLoading(false);
              },
              onError: () => setIsImageLoading(false),
            },
            largeImage: {
              src: imageUrl,
              width: 1500,
              height: 1800,
            },
            enlargedImageContainerStyle: {
              zIndex: 2,
            },
            enlargedImageContainerDimensions: {
              width: '200%',
              height: '100%'
            },
            shouldUsePositiveSpaceLens: true,
            lensStyle: {
              backgroundImage:
                'url("data:image/gif;base64,R0lGODlhZABkAPABAHOf4fj48yH5BAEAAAEALAAAAABkAGQAAAL+jI+py+0PowOB2oqvznz7Dn5iSI7SiabqWrbj68bwTLL2jUv0Lvf8X8sJhzmg0Yc8mojM5kmZjEKPzqp1MZVqs7Cr98rdisOXr7lJHquz57YwDV8j3XRb/C7v1vcovD8PwicY8VcISDGY2GDIKKf4mNAoKQZZeXg5aQk5yRml+dgZ2vOpKGraQpp4uhqYKsgKi+H6iln7N8sXG4u7p2s7ykvnyxos/DuMWtyGfKq8fAwd5nzGHN067VUtiv2lbV3GDfY9DhQu7p1pXoU+rr5ODk/j7sSePk9Ub33PlN+4jx8v4JJ/RQQa3EDwzcGFiBLi6AfN4UOGCyXegGjIoh0fisQ0rsD4y+NHjgZFqgB5y2Qfks1UPmEZ0OVLlIcKAAA7")',
              border: "1px solid #ccc",
            },
          }}
        />
        <canvas
          ref={canvasRef}
          id={`bounding-box-${transactionId}`}
          width={currentWidth}
          height={currentHeight}
          className={
            style.imageOverlayCanvas + " position-absolute top-0"
          }
          onClick={onCanvasClick}
        />

        <canvas
          ref={canvasReflectionRef}
          id={`bounding-box-reflection-${transactionId}`}
          width={currentWidth}
          height={currentHeight}
          className={
            style.imageOverlayCanvas + " position-absolute top-0"
          }
        />
      </div>

      <div className={"mt-1  d-md-none d-lg-flex justify-content-between"}>
        <h5>{bottomLeft}</h5>
        <h5>{bottomRight}</h5>
      </div>
    </div>
  );
};
