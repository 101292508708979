interface TenantConfig {
  apiUrl: string;
  threshold?: number;
  isBrandingLogoAvailable: boolean;
}

interface ConfigType {
  [id: string]: TenantConfig;
}

const config: ConfigType = {
  ALL_TENANTS: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  ORANGE_FR: {
    apiUrl: "REACT_APP_BNP_API_BASE_URL",
    isBrandingLogoAvailable: true,
  },
  BNPPARIBAS_FR: {
    apiUrl: "REACT_APP_BNP_API_BASE_URL",
    isBrandingLogoAvailable: true,
  },
  WINDTRE_IT: {
    apiUrl: "REACT_APP_EUROPE_API_BASE_URL",
    isBrandingLogoAvailable: true,
  },
  THREEIE_IE: {
    apiUrl: "REACT_APP_EUROPE_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  BOLT: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  BOLTTECH_HK: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  FRANK_FS_TH: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: true,
  },
  HKT: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    threshold: 0.5,
    isBrandingLogoAvailable: false,
  },
  RECONNECTS_HK: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: true,
  },
  UPLUS: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: true,
  },
  THREEHK_HK: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  FWD_HK: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  HOMECREDIT_FS_PH: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  HSBC_FS_MY: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  PAYMAYA_PH: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  HYUNDAICARD_KR: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  FWDLIFE_HK: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  HOTAI_TW: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: false,
  },
  AIS_TH: {
    apiUrl: "REACT_APP_ASIA_API_BASE_URL",
    isBrandingLogoAvailable: true,
  },
  'dev-oak': {
    apiUrl: "REACT_APP_OAK_API_BASE_URL",
    isBrandingLogoAvailable: false,
  }
};

export default config;
