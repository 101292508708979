import { SetStateAction } from "react";
import Table from "react-bootstrap/Table";
import Style from "../GroundTruthReportPage.module.scss";
import { LabelHelper } from "helpers/labelHelper";
import {
  ObservationType,
  OtherObservationsType,
} from "helpers/transaction.types";
import Form from "react-bootstrap/Form";
import { ALL, FP, FN } from "../constants";
import FalseNegative from "assets/icons/icon-false-negative.svg";
import FalsePositive from "assets/icons/icon-false-positive.svg";

type Props = {
  lang: string;
  otherObservations: OtherObservationsType;
  filteredOption: string;
  setFilteredOption: React.Dispatch<React.SetStateAction<string>>;
};

type RowProps = {
  observation: ObservationType;
};

const ObservationRow = ({ observation }: RowProps) => {
  const { categorisation, occurence, overallPercentage } = observation;

  return (
    <tr>
      <td>{categorisation}</td>
      <td>{occurence}</td>
      <td>
        <progress value={overallPercentage} max="100" />
        <span>{overallPercentage}%</span>
      </td>
    </tr>
  );
};

export const OtherObservations = ({
  lang,
  otherObservations,
  filteredOption,
  setFilteredOption,
}: Props) => {
  const handleOnChange = (e: { target: { id: SetStateAction<string> } }) =>
    setFilteredOption(e.target.id);

  return (
    <>
      <div className={Style.sectionContainer}>
        <div className={Style.sectionHeading}>
          {LabelHelper(lang).REPORT.OTHER_OBSERVATIONS.TITLE}
        </div>

        <Form>
          <div className="mb-3">
            <Form.Group controlId="filterOption">
              <span className="me-3">
                {LabelHelper(lang).REPORT.OTHER_OBSERVATIONS.SHOW}:
              </span>
              <Form.Check
                inline
                label={LabelHelper(lang).REPORT.OTHER_OBSERVATIONS.ALL}
                name="group1"
                type="radio"
                id={ALL}
                checked={filteredOption === ALL}
                onChange={handleOnChange}
                className={Style.allOption}
              />
              <Form.Check
                inline
                label={
                  LabelHelper(lang).REPORT.OTHER_OBSERVATIONS.FALSE_POSITIVE
                }
                name="group1"
                type="radio"
                id={FP}
                checked={filteredOption === FP}
                onChange={handleOnChange}
              />
              <img
                src={FalsePositive}
                alt="false positive"
                className={Style.svgImage}
              />
              <Form.Check
                inline
                label={
                  LabelHelper(lang).REPORT.OTHER_OBSERVATIONS.FALSE_NEGATIVE
                }
                name="group1"
                type="radio"
                id={FN}
                checked={filteredOption === FN}
                onChange={handleOnChange}
              />
              <img
                src={FalseNegative}
                alt="false negative"
                className={Style.svgImage}
              />
            </Form.Group>
          </div>
        </Form>

        <Table className="table table-borderless responsive">
          <thead>
            <tr>
              <th>
                {LabelHelper(lang).REPORT.OTHER_OBSERVATIONS.CATEGORISATION}
              </th>
              <th>{LabelHelper(lang).REPORT.OTHER_OBSERVATIONS.OCCURRENCE}</th>
              <th>
                {LabelHelper(lang).REPORT.OTHER_OBSERVATIONS.OVERALL_PERCENTAGE}
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(otherObservations).map((key) => (
              <ObservationRow observation={otherObservations[key]} key={key} />
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};
