import { Route, Routes, Navigate } from "react-router-dom";
import AnalystImageUpdatePage from "views/AnalystImageUpdatePage";
import TransactionsListPage from "views/transactionsListPage";
import { LoginPage } from "views/LoginPage";
import { ParentLayout } from "components/UI/ParentLayout";
import { ProtectedRoute } from "components/Core/ProtectedRoute";
import { GroundTruthReportPage } from "views/GroundTruthReportPage";
import { AuthVerify } from "./auth/AuthVerify";
import { isSessionExpired } from "helpers/authHelper";

type Props = {
  lang: string;
};

const C2PRoutes = ({ lang = "ENGLISH" }: Props) => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            isSessionExpired() ? (
              <Navigate to="/login" />
            ) : (
              <Navigate to="/transactions" />
            )
          }
        />
        <Route element={<LoginPage lang={lang} />} path="/login" />
        <Route element={<ParentLayout lang={lang} />} path="/">
          <Route
            path="transactions"
            element={
              <ProtectedRoute>
                <TransactionsListPage lang={lang} />
              </ProtectedRoute>
            }
          />
          <Route
            path="transaction/:id"
            element={
              <ProtectedRoute>
                <AnalystImageUpdatePage lang={lang} />
              </ProtectedRoute>
            }
          />
          <Route
            element={
              <ProtectedRoute>
                <GroundTruthReportPage lang={lang} />
              </ProtectedRoute>
            }
            path="transactions/report"
          />
        </Route>
      </Routes>

      <AuthVerify lang={lang} />
    </>
  );
};

export default C2PRoutes;
