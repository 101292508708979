import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { isSessionExpired } from "helpers/authHelper";

export const ProtectedRoute: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isUserAuthenticated } = useAuth();

  if (!isUserAuthenticated && isSessionExpired()) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};
