import React from "react";
import { Button } from "react-bootstrap";
import style from "./C2Pbutton.module.scss";

type Props = {
  variant?: string;
  text: string;
  disabled?: boolean;
  propStyle?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
  title?: string;
};

const C2Pbutton = ({
  variant = "primary",
  text,
  disabled = false,
  propStyle,
  onClick,
}: Props) => (
  <Button
    style={propStyle}
    className={`${style.c2pButton}
    ${variant === "secondary" ? style.secondaryCTA : ""}
    ${variant === "link" ? style.linkCTA : ""}
    `}
    disabled={disabled}
    variant={variant || "primary"}
    onClick={onClick}
  >
    {text}
  </Button>
);

export default C2Pbutton;
