import { getRequest, postRequest } from ".";
import { getAPIURLForATenantCode } from "helpers/transactionsHelper";
import { PayloadType } from "helpers/transaction.types";

export type Params = {
  tenantCode: string;
  startDate: string;
  endDate: string;
  notReport?: boolean;
};

export const getAllTransactions = (params: Params) => {
  const API_URL = getAPIURLForATenantCode(params.tenantCode);
  let url;
  if (params.notReport) {
    url = `${API_URL}/v${process.env.REACT_APP_API_VERSION}/summary-report`;
  } else {
    url = `${API_URL}/v${process.env.REACT_APP_API_VERSION}/device-assessments`;
  }
  return getRequest(url, { params });
};

export const updateTransaction = (
  payload: PayloadType,
  selectedTenant: string,
) => {
  const API_URL = getAPIURLForATenantCode(selectedTenant);
  const url = `${API_URL}/v${process.env.REACT_APP_API_VERSION}/analyst-image/update`;
  return postRequest(url, payload);
};
