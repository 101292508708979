import { AxiosRequestConfig } from "axios";
import EventBus from "auth/EventBus";
import { SESSION, AUTHORIZATION } from "../models/session.constants";
import { EVENT } from "models/event.constants";

// In order to use JWT on each private request, we need to add them to the request header as expected.
// Axios has a header common set option for doing the same
export const setAuthHeader = (config: AxiosRequestConfig) => {
  const token = getAccessToken();

  const deleteAuthFromHeader = () => delete config?.headers?.[AUTHORIZATION];

  if (token) {
    if (
      config.headers &&
      isNotRefreshTokenURL(config?.url) &&
      isNotSignoutURL(config?.url)
    ) {
      config.headers[AUTHORIZATION] = `Bearer ${token}`;
    } else {
      deleteAuthFromHeader();
    }
  } else {
    deleteAuthFromHeader();
  }
};

export const setIsUserLoggedIn = (hasLoggedIn: boolean) => {
  localStorage.setItem(SESSION.USER_LOGGED_IN, hasLoggedIn.toString());
};

export const getIsUserLoggedIn = () =>
  JSON.parse(localStorage.getItem(SESSION.USER_LOGGED_IN) || "false");

export const getAccessToken = () => localStorage.getItem(SESSION.ACCESS_TOKEN);

export const getRefreshToken = () =>
  localStorage.getItem(SESSION.REFRESH_TOKEN);

export const getRefreshTokenExpiry = () =>
  localStorage.getItem(SESSION.REFRESH_TOKEN_EXPIRY);

export const setAccessToken = (token: string) =>
  localStorage.setItem(SESSION.ACCESS_TOKEN, token);

export const setRefreshToken = (token: string) =>
  localStorage.setItem(SESSION.REFRESH_TOKEN, token);

export const setAccessTokenExpiry = (expiry: string) =>
  localStorage.setItem(SESSION.ACCESS_TOKEN_EXPIRY, expiry);

export const setRefreshTokenExpiry = (expiry: string) =>
  localStorage.setItem(SESSION.REFRESH_TOKEN_EXPIRY, expiry);

export const isSessionExpired = () => {
  const refreshTokenExpiry = getRefreshTokenExpiry();
  return Number(refreshTokenExpiry) < Date.now();
};

export const handleOnSessionExpiry = () => {
  EventBus.dispatch(EVENT.LOGOUT);
};

type sessionDataType = {
  access_token: string;
  refresh_token: string;
  expires_in: string;
  refresh_expires_in: string;
};

export const setReceivedTokenDataToLocal = ({
  access_token,
  refresh_token,
  expires_in,
  refresh_expires_in,
}: sessionDataType) => {
  // calculate actual refresh token expiry time in milliseconds
  const refreshTokenExpiry = Number(refresh_expires_in) * 1000 + Date.now();
  setAccessToken(access_token);
  setRefreshToken(refresh_token);
  setAccessTokenExpiry(expires_in);
  setRefreshTokenExpiry(refreshTokenExpiry.toString());
};

export const isNotRefreshTokenURL = (url: string | undefined) =>
  !url?.includes("refreshtoken");

export const isNotSignoutURL = (url: string | undefined) =>
  !url?.includes("signout");
