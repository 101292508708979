import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { SessionExpiryModal } from "./SessionExpiryModal";
import { getAccessToken, isSessionExpired } from "helpers/authHelper";
import EventBus from "./EventBus";
import { SESSION_CHECK_TIME } from "models/session.constants";
import { EVENT } from "models/event.constants";

type Props = { lang: string };

export const AuthVerify = ({ lang = "ENGLISH" }: Props) => {
  let location = useLocation();
  const { handleOnSessionExpiry } = useAuth();

  const token = getAccessToken();

  const [showSessionExpiryModal, setShowSessionExpiryModal] = useState(false);

  useEffect(() => {
    if (token && isSessionExpired()) {
      localStorage.clear();
    }
    const interval = setInterval(() => {
      if (token) {
        if (isSessionExpired()) {
          localStorage.clear();
          setShowSessionExpiryModal(true);
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }, SESSION_CHECK_TIME);

    return () => {
      clearInterval(interval);
    };
  }, [token, location]);

  useEffect(() => {
    EventBus.on(EVENT.LOGOUT, () => {
      setShowSessionExpiryModal(true);
    });

    return () => {
      EventBus.remove(EVENT.LOGOUT, () => {});
    };
  }, []);

  return (
    <SessionExpiryModal
      show={showSessionExpiryModal}
      handleClose={() => {
        setShowSessionExpiryModal(false);

        handleOnSessionExpiry();
      }}
      lang={lang}
    />
  );
};
