import Form from "react-bootstrap/Form";
import Style from "./ToggleSwitch.module.scss";

type Props = {
  isChecked: boolean;
  label: string;
  toggleSwitchID: string;
  setIsChecked: Function;
  propStyle?: React.CSSProperties;
};

const ToggleSwitch = ({ isChecked, label, toggleSwitchID = "custom-switch", setIsChecked, propStyle }: Props) => (
  <div className={Style.toggleContainer} style={propStyle}>
    <Form.Check
      type="switch"
      id={toggleSwitchID}
      label={label}
      onChange={(e) => setIsChecked(e.target.checked)}
      checked={isChecked}
    />
  </div>
);

export default ToggleSwitch;
