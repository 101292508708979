import { getRequest } from ".";
import { getAPIURLForATenantCode } from "helpers/transactionsHelper";

export type Params = {
  tenantCode: string;
  startDate: string;
  endDate: string;
};

export const getSummaryReport = (params: Params) => {
  const API_URL = getAPIURLForATenantCode(params.tenantCode);
  const url = `${API_URL}/v${process.env.REACT_APP_API_VERSION}/summary-report`;
  return getRequest(url, {
    params: {
      startDate: params.startDate,
      endDate: params.endDate,
      notReport: false,
    },
  });
};
