import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  current,
} from "@reduxjs/toolkit";
import {
  Params,
  getAllTransactions,
  updateTransaction,
} from "../services/api/transactions";
import { Txn, PayloadType, FiltersType } from "../helpers/transaction.types";
import {
  filterFrontScreenCrackedAndSubmittedStatusTransactions,
  getAdjustedDate,
} from "../helpers/transactionsHelper";
import { C2P_TESTS } from "models/constants";
import { handleOnSessionExpiry } from "helpers/authHelper";

type statusType = "idle" | "loading" | "succeeded" | "failed";

// Define a type for the slice state
interface TransactionsState {
  filters: FiltersType;
  allTransactions: Array<Txn>;
  submittedTransactions: Array<Txn>;
  status: statusType;
  statusWhilePostingUpdate: statusType;
  selectedTransaction: Txn;
}

// Define the initial state using that type
const initialState: TransactionsState = {
  filters: {
    tenantCode: "",
    startDate: getAdjustedDate(7),
    endDate: getAdjustedDate(1),
  },
  allTransactions: [] as Array<Txn>,
  submittedTransactions: [] as Array<Txn>,
  status: "idle",
  statusWhilePostingUpdate: "idle",
  selectedTransaction: {} as Txn,
};

export const transactionsSlice = createSlice({
  name: "transactions",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    selectTransaction: (state, action: PayloadAction<Txn>) => {
      state.selectedTransaction = action.payload;
    },
    selectNextTransaction: (state, action: PayloadAction<number>) => {
      const currentIndex = action.payload;
      const nextTxn = state.submittedTransactions[currentIndex + 1];
      state.selectedTransaction = nextTxn;
    },
    selectPreviousTransaction: (state, action: PayloadAction<number>) => {
      const currentIndex = action.payload;
      const previousTxn = state.submittedTransactions[currentIndex - 1];
      state.selectedTransaction = previousTxn;
    },
    setFilters: (state, action: PayloadAction<FiltersType>) => {
      state.filters = action.payload;
    },
    setLoadingStatus: (state, action: PayloadAction<statusType>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allTransactions = action.payload.transactions;
        state.submittedTransactions =
          filterFrontScreenCrackedAndSubmittedStatusTransactions(
            action.payload.transactions,
          );
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.status = "failed";
        state.submittedTransactions = [];
      })
      .addCase(submitTransaction.pending, (state, action) => {
        state.statusWhilePostingUpdate = "loading";
      })
      .addCase(submitTransaction.fulfilled, (state, action) => {
        state.statusWhilePostingUpdate = "succeeded";
        const { updatedPayload }: any = action.payload;

        const {
          transactionId,
          classifier,
          isBehaviorDifferent,
          tags,
          comments,
        }: PayloadType = updatedPayload;

        const transactions = current(state).submittedTransactions;
        const index = transactions.findIndex(
          (record) => record.transactionId === transactionId,
        );

        state.submittedTransactions[index].tests = transactions[
          index
        ].tests.map((test) =>
          test.name.includes(C2P_TESTS.FRONT_SCREEN_CRACK_TEST.name)
            ? {
                ...test,
                derived: { classifier, isBehaviorDifferent, tags, comments },
              }
            : test,
        );
      })
      .addCase(submitTransaction.rejected, (state, action) => {
        state.statusWhilePostingUpdate = "failed";
      });
  },
});

export const {
  selectTransaction,
  selectNextTransaction,
  selectPreviousTransaction,
  setFilters,
  setLoadingStatus,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;

const getErrorMessage = (error: any) => {
  return (
    error?.response?.data?.message ||
    error?.message ||
    "Something went wrong, please try again later."
  );
};

const isSessionExpired = (error: { response?: { status?: number } }) =>
  error.response &&
  (error.response.status === 401 ||
    error.response.status === 403 ||
    error.response.status === 400);

export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async (params: Params, { rejectWithValue }) => {
    try {
      const res = await getAllTransactions(params);
      return res.data;
    } catch (error: any) {
      if (!isSessionExpired(error)) {
        return rejectWithValue(getErrorMessage(error));
      }
      handleOnSessionExpiry();
      // session expiry modal will pop up hence no need to rejectWithValue error message for window alert message
    }
  },
);

export const submitTransaction = createAsyncThunk(
  "transactions/submitTransaction",
  async (
    payload: PayloadType,
    { getState, rejectWithValue, fulfillWithValue },
  ) => {
    try {
      const state: any = getState();
      const selectedTenantCode = state.transactions.filters.tenantCode;
      const res = await updateTransaction(payload, selectedTenantCode);
      return fulfillWithValue({
        updatedPayload: payload,
        message: res.data.message,
      });
    } catch (error: any) {
      if (!isSessionExpired(error)) {
        return rejectWithValue(getErrorMessage(error));
      }
      handleOnSessionExpiry();
      // session expiry modal will pop up hence no need to rejectWithValue error message for window alert message
    }
  },
);
