import Style from "../GroundTruthReportPage.module.scss";
import { useAppSelector } from "../../../hooks/hooks";
import C2Pbutton from "components/UI/C2Pbutton";
import { downloadPdfDocument } from "helpers/reportDownloadHelper";
import { getReportName } from "helpers/transactionsHelper";

export const DownloadButton = () => {
  const filters = useAppSelector((state) => state.transactions.filters);

  const reportName = getReportName(filters);

  return (
    <div className={Style.downloadButtonContainer}>
      <C2Pbutton
        text="Download"
        onClick={() => downloadPdfDocument(reportName)}
        propStyle={{ padding: "8px" }}
      />
    </div>
  );
};
