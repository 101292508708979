import axios from "axios";
import {
  getRefreshToken,
  setReceivedTokenDataToLocal,
  setAuthHeader,
  handleOnSessionExpiry,
} from "../../helpers/authHelper";
import { refreshToken } from "./session";

axios.interceptors.request.use(
  (config) => {
    setAuthHeader(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (
        (err.response.status === 403 || err.response.status === 401) &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true;

        try {
          const rs = await refreshToken(getRefreshToken());
          setReceivedTokenDataToLocal(rs?.data?.result);

          return axios(originalConfig);
        } catch (_error: any) {
          handleOnSessionExpiry();

          return Promise.reject(_error);
        }
      }

      if (err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  },
);

export const postRequest = axios.post;

export const putRequest = axios.put;

export const getRequest = axios.get;
