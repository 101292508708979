import Style from "../GroundTruthReportPage.module.scss";
import { LabelHelper } from "helpers/labelHelper";
import { TransactionSummaryType } from "helpers/transaction.types";

type Props = {
  lang: string;
  transactionSummary: TransactionSummaryType;
};

export const TransactionsSummary = ({
  lang,
  transactionSummary = {} as TransactionSummaryType,
}: Props) => {
  const { total, pending, submitted, inProgress } = transactionSummary;

  const getAccessPercent = () => {
    let accessData: number = total ? (1 - inProgress / total) : 0.0
    if(isNaN(accessData)) return "0.00"
    return accessData.toFixed(2)
  }

  const getCompletedPercent = () => {
    let completedData: number = submitted || inProgress ? (submitted / (submitted + inProgress)) : 0.0
    if(isNaN(completedData)) return "0.00"
    return completedData.toFixed(2)
  }

  return (
    <>
      <div className={Style.dataRow}>
        <div>
          <div className={Style.heading}>
            {LabelHelper(lang).REPORT.TRANSACTIONS_SUMMARY.TRANSACTIONS_PENDING}
          </div>
          <div className={Style.content}>{pending}</div>
        </div>
        <div>
          <div className={Style.heading}>
            {
              LabelHelper(lang).REPORT.TRANSACTIONS_SUMMARY
                .TRANSACTIONS_IN_PROGRESS
            }
          </div>
          <div className={Style.content}>{inProgress}</div>
          <div className={Style.conversionRateData + " mt-2"}>
            {LabelHelper(lang).REPORT.TRANSACTIONS_SUMMARY.ACCESS_DATA_PENDING +
              " "}
            {getAccessPercent()}
          </div>
        </div>
        <div>
          <div className={Style.heading}>
            {
              LabelHelper(lang).REPORT.TRANSACTIONS_SUMMARY
                .TRANSACTIONS_SUBMITTED
            }
          </div>
          <div className={Style.content}>{submitted}</div>
          <div className={Style.conversionRateData + " mt-2"}>
            {LabelHelper(lang).REPORT.TRANSACTIONS_SUMMARY
              .ACCESS_DATA_COMPLETED + " "}
            {getCompletedPercent()}
          </div>
        </div>
        <div>
          <div className={Style.heading}>
            {LabelHelper(lang).REPORT.TRANSACTIONS_SUMMARY.TOTAL_TRANSACTIONS}
          </div>
          <div className={Style.content}>{total}</div>
        </div>
      </div>
    </>
  );
};
