import Style from "../GroundTruthReportPage.module.scss";
import FalseNegative from "assets/icons/icon-false-negative.svg";
import FalsePositive from "assets/icons/icon-false-positive.svg";
import TrueNegative from "assets/icons/icon-true-negative.svg";
import TruePositive from "assets/icons/icon-true-positive.svg";
import { LabelHelper } from "helpers/labelHelper";
import { PredictionOutcomeType } from "helpers/transaction.types";
import { useAppSelector } from "hooks/hooks";
import { PercentageOutcome } from "./PercentageOutcome"

type Props = {
  lang: string;
  C2PBehaviourPredictionOutcome: PredictionOutcomeType;
};

export const ManualPredictionOutcome = ({
  lang,
  C2PBehaviourPredictionOutcome,
}: Props) => {
  const transactionsList = useAppSelector(
    (state) => state.transactions.submittedTransactions,
  );

  const { TP, TN, FP, FN } = C2PBehaviourPredictionOutcome;

 

  return (
    <>
      <div className={Style.sectionHeading}>
        <h5>{LabelHelper(lang).REPORT.MANUAL_PREDICTION_OUTCOME.TITLE}</h5>
        <PercentageOutcome lang={lang}/>
      </div>
      <div className={Style.dataRow}>
        <div>
          <img
            src={TruePositive}
            alt="true positive"
            className={Style.svgImage}
          />
          <span className={Style.heading}>
            {LabelHelper(lang).REPORT.MANUAL_PREDICTION_OUTCOME.TRUE_POSITIVE}
          </span>
          <div className={Style.content}>{TP}</div>
        </div>
        <div>
          <img
            src={FalsePositive}
            alt="false positive"
            className={Style.svgImage}
          />
          <span className={Style.heading}>
            {LabelHelper(lang).REPORT.MANUAL_PREDICTION_OUTCOME.FALSE_POSITIVE}
          </span>
          <div className={Style.content}>{FP}</div>
        </div>
        <div>
          <img
            src={TrueNegative}
            alt="true negative"
            className={Style.svgImage}
          />
          <span className={Style.heading}>
            {LabelHelper(lang).REPORT.MANUAL_PREDICTION_OUTCOME.TRUE_NEGATIVE}
          </span>
          <div className={Style.content}>{TN}</div>
        </div>
        <div>
          <img
            src={FalseNegative}
            alt="false negative"
            className={Style.svgImage}
          />
          <span className={Style.heading}>
            {LabelHelper(lang).REPORT.MANUAL_PREDICTION_OUTCOME.FALSE_NEGATIVE}
          </span>
          <div className={Style.content}>{FN}</div>
        </div>
      </div>
    </>
  );
};
