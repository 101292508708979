import {
  getTestResultForSinglePage,
  getFrontScreenCrackedTest,
  getTagOptions,
  getTxnCreatedDateTime,
  getClassifierPredictions,
} from "helpers/transactionsHelper";
import { useAppSelector } from "./hooks";
import { OTHER_TAGS } from "models/constants";
import { CLASSIFIER_PREDICTIONS_DIGITS_AFTER_DECIMAL } from "models/constants";

export const useTransactionData = () => {
  const selectedTransaction = useAppSelector(
    (state) => state.transactions.selectedTransaction,
  );

  const { transactionId } = selectedTransaction;

  const createdAt = getTxnCreatedDateTime(selectedTransaction);

  const deviceMakeAndModel = selectedTransaction?.makeAndModel;
  
  const testMethod = selectedTransaction?.testMethod;

  const test = getFrontScreenCrackedTest(selectedTransaction);
  const { imageUrl } = test;
  const result = getTestResultForSinglePage(test);

  const tagOptions = getTagOptions(Object.values(OTHER_TAGS));

  const classifierPredictions = getClassifierPredictions(
    test,
    CLASSIFIER_PREDICTIONS_DIGITS_AFTER_DECIMAL,
  );

  const isBehaviorDifferent = !!test?.derived?.isBehaviorDifferent;

  return {
    createdAt,
    test,
    imageUrl,
    testMethod,
    transactionId,
    result,
    tagOptions,
    deviceMakeAndModel,
    isBehaviorDifferent,
    classifierPredictions,
  };
};
