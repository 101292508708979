import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAccessToken,
  getRefreshToken,
  setReceivedTokenDataToLocal,
  setIsUserLoggedIn,
} from "helpers/authHelper";
import { signin, signout } from "services/api/session";
import { PayloadType } from "helpers/loginTypes";

export const useAuth = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const isUserAuthenticated = !!getAccessToken();

  const handleOnLogout = async () => {
    try {
      const refreshToken = getRefreshToken();
      localStorage.clear();
      navigate("/login");
      await signout(refreshToken);
    } catch (error) {
      alert("Unable to signout, please try again later!");
      console.error(error);
    }
  };

  const handleOnSessionExpiry = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleOnLogin = async ({ username, password }: PayloadType) => {
    try {
      setIsLoading(true);
      const { data: loginData } = await signin({
        username,
        password,
      });
      setIsLoading(false);
      navigate("/transactions");
      setIsUserLoggedIn(true);
      isError && setIsError(false);
      setReceivedTokenDataToLocal(loginData?.result);
    } catch (err: any) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  return {
    isUserAuthenticated,
    handleOnLogout,
    isError,
    isLoading,
    handleOnLogin,
    handleOnSessionExpiry,
  };
};
