import Select from "react-select";
import "./formSelectBox.scss";

type Props = {
  name?: string;
  options: any;
  defaultValue?: object;
  isMulti: boolean;
  onChange?: any;
  value?: object;
  placeholder?: string;
  variant: string;
  isSearchable?: boolean;
  disabled?: boolean;
  propStyles?: object;
};

const FormSelectBox = ({
  name = "formSelect",
  options = [],
  defaultValue = [],
  value,
  isMulti = false,
  onChange,
  placeholder,
  variant = "primary",
  isSearchable=true,
  disabled,
  propStyles
}: Props) => (
  <Select
    classNamePrefix="c2p-react-select"
    className={variant === "primary" ? "formSelectBoxPrimary" : "formSelectBoxSecondary"}
    styles={{
      control: (provided, state) => ({
        ...provided,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 0,
        border: "none",
        height: "48px",
        ...propStyles
      }),
    }}
    name="multiSelect"
    isMulti={isMulti}
    defaultValue={defaultValue}
    value={value}
    options={options}
    onChange={onChange}
    placeholder={placeholder}
    isDisabled={disabled}
    isSearchable={isSearchable}
  />
);

export default FormSelectBox;
