import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import style from "./RadioButton.module.scss";

type Radio = { name: string; value: string };

type Props = {
  radios: Array<Radio>;
  radioValue: string;
  setRadioValue: React.Dispatch<React.SetStateAction<string>>;
};

export const RadioButton = ({ radios, radioValue, setRadioValue }: Props) => (
  <ButtonGroup className={style.RadioButtonGroup}>
    {radios.map((radio, idx) => (
      <ToggleButton
        className={
          `${style.radioButton}` + ` ${radioValue === radio.value ? style.active : ""}`
        }
        key={idx}
        id={`radio-${idx}`}
        type="radio"
        variant="outline-primary"
        name="radio"
        value={radio.value}
        checked={radioValue === radio.value}
        onChange={(e) => {
          setRadioValue(e.currentTarget.value);
        }}
      >
        {radio.name}
      </ToggleButton>
    ))}
  </ButtonGroup>
);
