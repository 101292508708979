import Table from "react-bootstrap/Table";
import Style from "../GroundTruthReportPage.module.scss";
import { LabelHelper } from "helpers/labelHelper";
import { MetricsType } from "helpers/transaction.types";
import { getClassifiedImageData, getConfidenceInterval } from "helpers/transactionsHelper";
import { useAppSelector } from "hooks/hooks";

type Props = {
  lang: string;
  metrics: MetricsType;
  title: string;
};

export const HumanEyePerformanceMetrics = ({ lang, metrics, title }: Props) => {
  const { accuracy, precision, recall, f1Score } = metrics;

  const transactionsList = useAppSelector(
    (state) => state.transactions.submittedTransactions,
  );

const { classifiedTransactions, totalTransactions } =
getClassifiedImageData(transactionsList);

  return (
    <>
      <div className={Style.sectionContainer}>
        <div className={Style.sectionHeading}>{title}</div>
        <h6 className={Style.confidenceIntervalHeading}>{LabelHelper(lang).REPORT.METRICS.CONFIDENCE_INTERVAL_TITLE}</h6>
        <Table className="table table-borderless" size="sm" responsive="sm">
          <thead>
            <tr>
            <th>{LabelHelper(lang).REPORT.METRICS.METRIC}</th>
            <th>{LabelHelper(lang).REPORT.METRICS.PERFORMANCE}</th>
            <th>{LabelHelper(lang).REPORT.METRICS.NINETY_FIVE_PERCENT_CI}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{LabelHelper(lang).REPORT.METRICS.ACCURACY}</td>
              <td>
                <progress value={accuracy} max="100" />
                <span>{accuracy?.toFixed(2)}%</span>
              </td>
              <td>{getConfidenceInterval({p:accuracy/100, n: classifiedTransactions, N: totalTransactions})}</td>
            </tr>
            <tr>
              <td>{LabelHelper(lang).REPORT.METRICS.PRECISION}</td>
              <td>
                <progress value={precision} max="100" />
                <span>{precision?.toFixed(2)}%</span>
              </td>
              <td>{getConfidenceInterval({p:precision/100, n: classifiedTransactions, N: totalTransactions})}</td>
            </tr>
            <tr>
              <td>{LabelHelper(lang).REPORT.METRICS.RECALL}</td>
              <td>
                <progress value={recall} max="100" />
                <span>{recall?.toFixed(2)}%</span>
              </td>
              <td>{getConfidenceInterval({p:recall/100, n: classifiedTransactions, N: totalTransactions})}</td>
            </tr>
            <tr>
              <td>{LabelHelper(lang).REPORT.METRICS.F1_SCORE}</td>
              <td>
                <progress value={f1Score} max="100" />
                <span>{f1Score?.toFixed(2)}%</span>
              </td>
              <td>{getConfidenceInterval({p:f1Score/100, n: classifiedTransactions, N: totalTransactions})}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};
