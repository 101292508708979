import Toast from 'react-bootstrap/Toast';
import { CloseButton } from 'react-bootstrap';
import ToastContainer from 'react-bootstrap/ToastContainer';
import DoneCircleIcon from "assets/icons/icon-done-circle.svg";
import { ReactSVG } from 'react-svg';
import parse from 'html-react-parser';
import style from "./toastNotification.module.scss";
import { ToastNotificationType } from 'helpers/commonComponents.types';

const ToastNotification = (props: { toastData: ToastNotificationType, autoCloseToast: any }) => {
    const { toastData, autoCloseToast } = props;

    return (
        <>
            <ToastContainer className={style.toastNotificationContainer + " p-3 mt-1"}
                position={toastData.position}
            >
                <Toast
                    className={style.toastMain}
                    onClose={autoCloseToast}
                    show={toastData.show} delay={toastData.autoHide} autohide={toastData.autoHide ? true : false}>
                    <Toast.Body className='p-3 d-flex align-items-center'>
                        <ReactSVG className="me-3" src={toastData.icon ? toastData.icon : DoneCircleIcon} />
                        <p className={style.toastDescription + " mb-0"} style={{ flex: 1 }} >
                            {parse(toastData?.description)}
                        </p>
                        {toastData?.dismissible && <CloseButton onClick={autoCloseToast} className={style.closeBtn + " float-end"} />}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
};

export default ToastNotification;
