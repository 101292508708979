import Header from "components/UI/header";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../Sidebar";
import style from "./parentLayout.module.scss";
import ToastNotification from "../ToastNotification";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { setToastNotification } from "store/commonSlice";

type Props = {
  lang: string;
};

export const ParentLayout = ({ lang }: Props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [showSidebar, setShowSidebar] = React.useState(true);

  const toastNotification = useAppSelector(
    (state) => state.commonComponents.toastNotification
  );

  React.useEffect(() => {
    if (toastNotification.show) {
      closeToastNotification()
    }
    
    if (location?.pathname === "/transactions/report") {
      setShowSidebar(false)
    }
    else {
      setShowSidebar(true)
    }
  }, [location])
  
  const closeToastNotification = () => {
    dispatch(setToastNotification({
      ...toastNotification,
      show: false
    }))
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {
            showSidebar ?
              <div className={style.sideBar + " col-2 gx-0"}>
                <Sidebar lang={lang} />
              </div>
              : null
          }
          <div className={`gx-0 ${showSidebar ? "col-10" : "col-12"}`}>
            <Header lang={lang} />
            <Outlet />
          </div>
        </div>
      </div>

      <ToastNotification toastData={toastNotification} autoCloseToast={closeToastNotification} />
    </>
  );
} 
