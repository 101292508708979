import moment from "moment";
import {
  Txn,
  TestType,
  DerivedType,
  ClassifierPredictionsType,
  PredictionOutcomeType,
  FiltersType,
  MakeAndModel,
  CIFormulaParamsType
} from "./transaction.types";
import { C2P_TESTS } from "models/constants";
import config from "config";
import {
  DEFAULT_CLASSIFIER_THRESHOLD_VALUE,
  ML_CLASSIFICATION_TERMS,
  TEST_RESULT_CRACK_PROBABILITY_DIGITS_AFTER_DECIMAL,
} from "models/constants";
import { ALL_TENANTS } from "models/constants";
import { Z_SCORE } from "views/GroundTruthReportPage/constants";

// returns date in YYYY-MM-DD format
export const dateToYMD = (date: Date) =>
  date && date.toISOString().slice(0, 10);

export const getFrontScreenCrackedTest = (transaction: Txn) =>
  (transaction?.tests || []).find((test) =>
    test.name.includes(C2P_TESTS.FRONT_SCREEN_CRACK_TEST.name),
  ) || ({} as TestType);

export const getFrontScreenCrackedAndSubmittedTest = (transaction: Txn) =>
  transaction.tests.find(
    (test) =>
      test.name.includes(C2P_TESTS.FRONT_SCREEN_CRACK_TEST.name) &&
      test.status === "SUBMITTED",
  );

export const filterFrontScreenCrackedAndSubmittedStatusTransactions = (
  transactionsList: Txn[],
) =>
  transactionsList.filter((record) =>
    getFrontScreenCrackedAndSubmittedTest(record),
  );

const getFormattedStatus = (status: string) =>
  status && status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

export const getTestResultForSinglePage = (test: TestType) =>
  test?.results?.crackedProbability;

export const getTestResult = (test: TestType) => {
  const crackProbability = test?.results?.crackedProbability;
  if (!crackProbability) {
    return null;
  }
  return Number(crackProbability).toFixed(
    TEST_RESULT_CRACK_PROBABILITY_DIGITS_AFTER_DECIMAL,
  );
};

export const getTxnCreatedDateTime = (transaction: Txn) =>
  moment.utc(transaction.createdAt).format("MMMM Do YYYY, h:mm a");

export const getTestClassifier = (test: TestType, tenantCode: string) => {
  const result = test?.results?.crackedProbability;

  const threshold = getClassifierThresholdValue(tenantCode);

  // no result or no classifier implies, not yet marked by admin user so return blank string

  if (!test?.derived || !result) {
    return "";
  }
  let { classifier } = test.derived as DerivedType;

  return checkClassifyType(result, classifier, threshold);
};

export const getTestReclassifier = (test: TestType, tenantCode: string) => {
  const result = test?.results?.crackedProbability;

  const threshold = getClassifierThresholdValue(tenantCode);

  // no result or no classifier implies, not yet marked by admin user so return blank string

  if (!test?.derived || !result) {
    return "";
  }
  let { classifier, isBehaviorDifferent } = test.derived as DerivedType;

  if (isBehaviorDifferent) classifier = !classifier;

  return checkClassifyType(result, classifier, threshold);
};

const checkClassifyType = (
  result: number,
  classifier: boolean,
  threshold: number,
) => {
  if (result >= threshold) {
    // device predicted to be cracked

    if (classifier) {
      // classified true implies true positive (Broken phone classified as broken)
      return ML_CLASSIFICATION_TERMS.TRUE_POSITIVE;
    }

    // classifier false implies false positive (Non broken phone misqualified as broken)
    return ML_CLASSIFICATION_TERMS.FALSE_POSITIVE;
  } else {
    // < CLASSIFIER_THRESHOLD_VALUE means device is predicted to be not cracked
    if (classifier) {
      // classified true implies false negative (Broken phone misqualified as non broken)
      return ML_CLASSIFICATION_TERMS.FALSE_NEGATIVE;
    }
    // classifier false implies true negative (Non broken phone classified as non broken)
    return ML_CLASSIFICATION_TERMS.TRUE_NEGATIVE;
  }
};

export const getProcessedTestData = (test: TestType, tenantCode: string) => {
  const name = test.name;
  const status = getFormattedStatus(test.status);
  const result = getTestResult(test);
  const testMethod = test?.method || '-';
  const classifier = getTestClassifier(test, tenantCode);
  const reclassifiedPerformance = getTestReclassifier(test, tenantCode);

  return { name, status, result, classifier, reclassifiedPerformance, testMethod };
};

export const getTenantList = () => {
  const tenantsForCurrentEnv: Array<string> = process.env.REACT_APP_TENANT_CODES
    ? JSON.parse(process.env.REACT_APP_TENANT_CODES)
    : [];

  if (!tenantsForCurrentEnv.length) {
    return undefined;
  }

  tenantsForCurrentEnv.push(ALL_TENANTS);

  return tenantsForCurrentEnv.map((tenantCode) => ({
    value: tenantCode,
    label: tenantCode,
  }));
};

export const getAPIURLForATenantCode = (tenantCode: string) =>
  process.env[config[tenantCode].apiUrl];

export const getTagOptions = (tags: Array<string>) =>
  tags.map((tag) => ({
    value: tag,
    label: tag,
  }));

export const getClassifierThresholdValue = (tenantCode: string) =>
  config[tenantCode]?.threshold || DEFAULT_CLASSIFIER_THRESHOLD_VALUE;

const getFormattedClassifier = (input: string, digits: number) =>
  Number(input).toFixed(digits);

export const getClassifierPredictions = (
  test: TestType,
  digitsAfterDecimal: number,
): ClassifierPredictionsType => {
  const { classifierPredictions } = test?.results || {};
  if (classifierPredictions) {
    return {
      topLeft: getFormattedClassifier(
        classifierPredictions.topLeft,
        digitsAfterDecimal,
      ),
      topRight: getFormattedClassifier(
        classifierPredictions.topRight,
        digitsAfterDecimal,
      ),
      bottomLeft: getFormattedClassifier(
        classifierPredictions.bottomLeft,
        digitsAfterDecimal,
      ),
      bottomRight: getFormattedClassifier(
        classifierPredictions.bottomRight,
        digitsAfterDecimal,
      ),
    };
  }
  return {} as ClassifierPredictionsType;
};

export const getClassifiedImageData = (transactionsList: Txn[]) => {
  const classifiedTransactions = transactionsList.filter(
    (record) => getFrontScreenCrackedAndSubmittedTest(record)?.derived,
  ).length;
  const totalTransactions = transactionsList.length;
  return {
    percentage: (classifiedTransactions / totalTransactions) * 100,
    classifiedTransactions,
    totalTransactions,
  };
};

export const getAccuracy = (predictionOutcome: PredictionOutcomeType) => {
  const { TP, TN, FP, FN } = predictionOutcome;
  const numerator = TP + TN; //correct predictions
  const denominator = TP + FP + TN + FN; // all predictions
  
  return denominator ? (numerator / denominator) * 100 : 0;
};

export const getPrecision = (predictionOutcome: PredictionOutcomeType) => {
  const { TP, FP } = predictionOutcome;
  return TP || FP ? (TP / (TP + FP)) * 100 : 0;
};

export const getRecall = (predictionOutcome: PredictionOutcomeType) => {
  const { TP, FN } = predictionOutcome;
  return TP || FN ? (TP / (TP + FN)) * 100 : 0;
};

export const getF1Score = (predictionOutcome: PredictionOutcomeType) => {
  const precision = getPrecision(predictionOutcome);
  const recall = getRecall(predictionOutcome);
  const numerator = 2 * precision * recall;
  const denominator = precision + recall;
  return denominator ? numerator / denominator : 0;
};

export const getReportName = (filters: FiltersType) => {
  const { tenantCode, startDate, endDate } = filters;
  const DATE_FORMAT = "YYYY-MM-DD";
  const env = process.env.REACT_APP_ENV;
  const start = moment(startDate).format(DATE_FORMAT);
  const end = moment(endDate).format(DATE_FORMAT);
  const reportName = `C2P_${env}_${tenantCode}_${start}to${end}`;

  return reportName;
};

export const getMakeAndModel = (deviceData: MakeAndModel) => {
  const { vendor, marketingName, model, osName } = deviceData;
  if (vendor && marketingName) {
    if (osName === "iOS") {
      return `${vendor} ${marketingName}`;
    }
    if (osName === "Android") {
      return `${vendor} ${marketingName} ${model ? " - " + model : ""}`;
    }
  }
  return "-";
};

export const getLogoSrc = (tenantCode: string) => {
  if (config[tenantCode].isBrandingLogoAvailable) {
    return require(`../themes/${tenantCode}/images/branding-logo.svg`);
  } else {
    return require(`../themes/bolttech/images/branding-logo.svg`)?.default;
  }
};

export const getAdjustedDate = (
  noOfPreviousDays: number,
  date = new Date(),
) => {
  date.setDate(date.getDate() - noOfPreviousDays);
  return date;
};

export const getConfidenceInterval = ({ p, n, N }: CIFormulaParamsType) => {
  // CI = p +/- z*(sqrt(p*(1-p)/n) * sqrt((N-n)/(N-1)))
  // calculate and return the error part only
  const error =
    Z_SCORE * (Math.sqrt((p * (1 - p)) / n) * Math.sqrt((N - n) / (N - 1)));

  return isNaN(error) ? '0.00' : `+/- ${error.toFixed(2)}`;
};
