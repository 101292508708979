export const AUTHORIZATION = "Authorization";

export const SESSION = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  REFRESH_TOKEN_EXPIRY: "refresh_expires_in",
  ACCESS_TOKEN_EXPIRY: "expires_in",
  USER_LOGGED_IN: "user_logged_in",
};

const MINUTE = 1 * 60 * 1000;
export const SESSION_CHECK_TIME = 0.5 * MINUTE;
