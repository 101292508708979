import { useState } from "react";
import Style from "./GroundTruthReportPage.module.scss";
import { Metrics } from "./components/Metrics";
import { HumanEyePerformanceMetrics } from "./components/HumanEyePerformanceMetrics"
import { OtherObservations } from "./components/OtherObservations";
import { ManualPredictionOutcome } from "./components/ManualPredictionOutcome";
import { CrackedScreenTestMLModelResult } from "./components/CrackedScreenTestMLModelResult";
import { ReportHeader } from "./components/ReportHeader";
import { DownloadButton } from "./components/DownloadButton";
import { TransactionsSummary } from "./components/TransactionsSummary";
import { useReportData } from "hooks/useReportData";
import { PredictionOutcomeType } from "helpers/transaction.types";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { LabelHelper } from "helpers/labelHelper";
import { ALL } from "./constants";
import { HumanEyePredictionOutcome } from "./components/HumanEyePredictionOutcome";

type Props = {
  lang: string;
};

export const GroundTruthReportPage = ({ lang }: Props) => {
  const [filteredOption, setFilteredOption] = useState(ALL);

  const {
    transactionSummary,
    crackedScreenTestMLModelResult,
    C2PBehaviourPredictionOutcome,
    humanEyePredictionOutcome,
    otherObservations,
    humanEyeMetrics,
    C2PBehaviourMetrics,
  } = useReportData(filteredOption);

  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-12 col-md-4 pb-4">
          <Link to="/transactions">
            <Button variant="link" style={{ marginRight: "16px" }}>
              {LabelHelper(lang).SINGLE_TRANSACTION.BACK}
            </Button>
          </Link>
        </div>

        <div className="col-12 col-md-8 pb-4">
          <DownloadButton />
        </div>
      </div>

      <div className={Style.reportContainer} id="report">
        <div id="genericReportData" className={Style.reportDownloadSection}>
          <ReportHeader lang={lang} />

          <TransactionsSummary
            lang={lang}
            transactionSummary={transactionSummary}
          />

          <CrackedScreenTestMLModelResult
            lang={lang}
            crackedScreenTestMLModelResult={crackedScreenTestMLModelResult}
          />
        </div>

        <div
          id="humanEyePredictionReportData"
          className={Style.reportDownloadSection}
        >
          <HumanEyePredictionOutcome
            lang={lang}
            humanEyePredictionOutcome={
              humanEyePredictionOutcome as PredictionOutcomeType
            }
          />

          <div className={Style.lastRow}>
            <OtherObservations
              lang={lang}
              otherObservations={otherObservations}
              filteredOption={filteredOption}
              setFilteredOption={setFilteredOption}
            />

            <HumanEyePerformanceMetrics
              lang={lang}
              metrics={humanEyeMetrics}
              title={
                LabelHelper(lang).REPORT.METRICS.HUMAN_EYE_PREDICTION_TITLE
              }
            />
          </div>
        </div>

        <div
          id="manualPredictionOutcomeReportData"
          className={Style.reportDownloadSection}
        >
          <ManualPredictionOutcome
            lang={lang}
            C2PBehaviourPredictionOutcome={
              C2PBehaviourPredictionOutcome as PredictionOutcomeType
            }
          />
          <div className={Style.lastRow}>
            <Metrics
              lang={lang}
              metrics={C2PBehaviourMetrics}
              title={LabelHelper(lang).REPORT.METRICS.C2P_BEHAVIOUR_TITLE}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
