import Spinner from "react-bootstrap/Spinner";
import style from "./page-loader.module.scss";
import { LabelHelper } from "helpers/labelHelper";

type Props = {
  show: boolean;
  lang: string;
};

export const PageLoader = ({ show, lang }: Props) => (
  <div className={`${show ? "" : "d-none"}  ${style.pageLoader}`}>
    <div className={style.content}>
      <span>
        <Spinner
          className={style.loaderIcon}
          animation="border"
          role="status"
        />
      </span>
      <span className={style.loaderText}>
        {LabelHelper(lang).PAGE_LOADER.LOADING_TEXT}
      </span>
    </div>
  </div>
);
