import Style from "../GroundTruthReportPage.module.scss";
import { LabelHelper } from "helpers/labelHelper";
import { useAppSelector } from "hooks/hooks";
import moment from "moment";
import { getLogoSrc } from "helpers/transactionsHelper";

type Props = {
  lang: string;
};

export const ReportHeader = ({ lang }: Props) => {
  const filters = useAppSelector((state) => state.transactions.filters);
  const { tenantCode, startDate, endDate } = filters;

  const DATE_FORMAT = "DD MMM, YYYY";
  const start = moment(startDate).format(DATE_FORMAT);
  const end = moment(endDate).format(DATE_FORMAT);

  return (
    <>
      <div className={Style.reportHeading + " row"}>
        <div className="col-md-4">
          <div>{LabelHelper(lang).REPORT.TITLE}</div>
        </div>
        <div className="col-md-8 text-end">
          <img
            src={getLogoSrc(tenantCode)}
            alt="app-logo"
            aria-label="logo"
            className={Style.logo}
            id="logo"
          />
        </div>
      </div>
      <div className={Style.tenantName}>{tenantCode}</div>
      <div className={Style.dateRange}>
        {start}
        <span className={Style.dateSeparator}>-</span>
        {end}
      </div>
    </>
  );
};
