import { PayloadType } from "helpers/loginTypes";
import { postRequest } from ".";

const API_URL = process.env.REACT_APP_AUTH_URL;

export const signin = (payload: PayloadType) => {
  const url = `${API_URL}/v${process.env.REACT_APP_API_VERSION}/auth/login`;
  return postRequest(url, payload);
};

export const refreshToken = (token: string | null) => {
  const url = `${API_URL}/v${process.env.REACT_APP_API_VERSION}/auth/refreshtoken`;
  return postRequest(url, { refreshToken: token });
};

export const signout = (token: string | null) => {
  const url = `${API_URL}/v${process.env.REACT_APP_API_VERSION}/auth/signout`;
  return postRequest(url, { refreshToken: token });
};
