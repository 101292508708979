import { LabelHelper } from "helpers/labelHelper";
import { getClassifiedImageData } from "helpers/transactionsHelper";
import { useAppSelector } from "hooks/hooks";

type Props = {
    lang: string;
  };

export const PercentageOutcome=({lang}: Props)=>{
    const transactionsList = useAppSelector(
        (state) => state.transactions.submittedTransactions,
      );

    const { percentage, classifiedTransactions, totalTransactions } =
    getClassifiedImageData(transactionsList);

    return <p>
    {`${
      LabelHelper(lang).REPORT.SUBTITLE
    } ${percentage?.toFixed(
      2,
    )} % (${classifiedTransactions}/${totalTransactions}) ${
      LabelHelper(lang).REPORT.SUBTITLE2
    }`}
  </p>
}