import { LabelHelper } from "helpers/labelHelper";
import Modal from "react-bootstrap/Modal";
import C2Pbutton from "components/UI/C2Pbutton";
import style from "./Guidelines.module.scss";

type Props = {
  lang: string;
  showGuidelines: boolean;
  handleClose: () => void;
};

export const Guidelines = ({ lang, showGuidelines, handleClose }: Props) => {
  return (
    <Modal className={style.guidelineModalPopup} show={showGuidelines} onHide={handleClose} size="lg" fullscreen="md">
      <Modal.Header>
        <Modal.Title className={style.guidelineModalTitle + " py-3 px-2"}>
          {LabelHelper(lang).SINGLE_TRANSACTION.GUIDELINE_HEADING}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={style.guidelineModalBody}>
        <div className={style.listHeading + " px-2 pt-2 pb-4"}>
          {LabelHelper(lang).SINGLE_TRANSACTION.GUIDELINE_SUBHEADING}
        </div>
        <ol>
          {Object.values(LabelHelper(lang).SINGLE_TRANSACTION.GUIDELINES).map(
            (guideline, index) => (
              <li key={`guideline_${index}`}>
                <div dangerouslySetInnerHTML={{ __html: guideline }}></div>
              </li>
            ),
          )}
        </ol>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <C2Pbutton
          propStyle={{
            padding: "10px 24px",
            borderRadius: 0,
            marginTop: "24px",
          }}
          onClick={handleClose}
          text={LabelHelper(lang).SINGLE_TRANSACTION.MODAL_CLOSE}
        />
      </Modal.Footer>
    </Modal>
  );
};
