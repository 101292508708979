import { useEffect, useState } from "react";
import style from "./analystImageUpdatePage.module.scss";
import C2Pbutton from "components/UI/C2Pbutton";
import { LabelHelper } from "helpers/labelHelper";
import { PayloadType } from "helpers/transaction.types";
import { RadioButton } from "components/UI/RadioButton";
import { useTransactionData } from "hooks/useTransactionData";
import { useTransactionNavigator } from "hooks/useTransactionNavigator";
import { getMakeAndModel } from "helpers/transactionsHelper";
import { submitTransaction } from "store/transactionsSlice";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { PageLoader } from "components/UI/page-loader";
import { useParams } from "react-router-dom";
import ToggleSwitch from "components/UI/ToggleSwitch";
import { DeviceImage } from "components/Core/DeviceImage";
import { Guidelines } from "./components/Guidelines";
import HelpCircle from "assets/icons/help-circle.svg";
import ICON_CRACKED_SCREEN from "assets/icons/icon-cracked-screen.svg";
import ICON_EYE from "assets/icons/icon-eye-on.svg";
import ICON_VIEW_LIST from "assets/icons/icon-view-list.svg";
import ICON_MONITOR from "assets/icons/icon-monitor.svg";
import { Navigate } from "react-router-dom";
import { TagList } from "./components/TagList";
import { Form } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import { setToastNotification } from "store/commonSlice";

type Props = {
  lang: string;
};

const AnalystImageUpdatePage = ({ lang }: Props) => {
  const [radioValue, setRadioValue] = useState("");
  const [comments, setComments] = useState("");
  const [isBoundingBoxEnabled, setIsBoundingBoxEnabled] = useState(false);
  const [isReflectionBoundingBoxEnabled, setIsReflectionBoundingBoxEnabled] =
    useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [behaviorDifferentCheckbox, setBehaviorDifferentCheckbox] =
    useState(false);
  const [isSubmitCTADisabled, setSubmitCTADisabled] = useState(true);

  const { id } = useParams();

  const dispatch = useAppDispatch();
  const loadingStatus = useAppSelector(
    (state) => state.transactions.statusWhilePostingUpdate,
  );
  const toastNotification = useAppSelector(
    (state) => state.commonComponents.toastNotification
  );

  const radios = [
    { name: LabelHelper(lang).SINGLE_TRANSACTION.CTA_CRACKED, value: "1" },
    { name: LabelHelper(lang).SINGLE_TRANSACTION.CTA_NOT_CRACKED, value: "2" },
  ];

  const {
    createdAt,
    test,
    imageUrl,
    transactionId,
    result,
    testMethod,
    tagOptions,
    deviceMakeAndModel,
    isBehaviorDifferent,
    classifierPredictions,
  } = useTransactionData();

  const {
    recordNumber,
    onPrevious,
    onNext,
    hasNoNextRecord,
    hasNoPreviousRecord,
    totalTransactions,
    selectTransactionById,
  } = useTransactionNavigator();

  useEffect(() => {
    if (id !== transactionId && typeof id === "string") {
      selectTransactionById(id);
    }
  }, [id, selectTransactionById, transactionId]);

  useEffect(() => {
    setComments("");
    setRadioValue("");
    setTags([]);
    setIsBoundingBoxEnabled(false);
    setIsReflectionBoundingBoxEnabled(false);
    setBehaviorDifferentCheckbox(isBehaviorDifferent);
  }, [transactionId, isBehaviorDifferent]);

  useEffect(() => {
    if (test?.derived?.classifier === true) {
      setRadioValue("1");
    } else if (test?.derived?.classifier === false) {
      setRadioValue("2");
    } else {
      // classifier is undefined
      setSubmitCTADisabled(true);
    }

    if (test?.derived?.comments) {
      setComments(test?.derived?.comments);
    }

    if (test?.derived?.tags?.length) {
      setTags(test.derived.tags);
    }
  }, [test]);

  const payload: PayloadType = {
    transactionId,
    testName: test.name,
    classifier: radioValue === "1" ? true : false,
    comments: comments?.length ? comments : null,
    tags,
    isBehaviorDifferent: behaviorDifferentCheckbox,
  };

  const isCommentDisabled = !tags.find((tag) => tag === "Other comments");

  const handleCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComments(e.target.value);
    updateSubmitDisabledStatus();
  };

  const handleOnSubmit = async () => {
    try {
      await dispatch(submitTransaction(payload)).unwrap();
      const notificationText = LabelHelper(lang).SINGLE_TRANSACTION.SUBMIT_NOTIFICATION_TEXT.replace("<>", `<u>${transactionId}</u>`);
  
      dispatch(setToastNotification({
        ...toastNotification,
        show: toastNotification.show ? false : true,
        description: notificationText
      }));
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    if (isCommentDisabled) {
      setComments("");
    } else {
      setComments(test?.derived?.comments || "");
    }
  }, [isCommentDisabled, test?.derived?.comments]);

  if (!transactionId) {
    //i.e. no transaction data exists
    return <Navigate to="/transactions" />;
  }

  const toggleBoundingBoxSwitch = (
    status: boolean,
    isReflectionBBox = false,
  ) => {
    const canvasElement = document.getElementById(
      isReflectionBBox
        ? `bounding-box-reflection-${transactionId}`
        : `bounding-box-${transactionId}`,
    );
    if (canvasElement) {
      canvasElement.style.zIndex = status ? "2" : "-1";
    }
    isReflectionBBox
      ? setIsReflectionBoundingBoxEnabled(status)
      : setIsBoundingBoxEnabled(status);
  };

 const updateSubmitDisabledStatus = () => {
   if (radioValue) {
     // enable submit button
     setSubmitCTADisabled(false);
     return;
   }
   // otherwise disable submit button
   setSubmitCTADisabled(true);
 };

  return (
    <>
      <div className={style.singleTransactionPage + " container-fluid p-4 mb-5"}>
        <div className="row">
          <div className="col-md-8">
            <div className={style.breadcrumbs}>
              <p>
                {LabelHelper(lang).SINGLE_TRANSACTION.TXN_LIST}
                <span className="ms-3">&gt;</span>
                <span className={style.trxnID + " ms-2"}>{transactionId}</span>
              </p>
            </div>
          </div>
          <div className="col-md-4 text-end">
            <div
              className={style.guideline}
              onClick={() => setShowGuidelines(true)}
            >
              <h4>
                <img src={HelpCircle} alt="help circle" /> &nbsp;
                {LabelHelper(lang).SINGLE_TRANSACTION.GUIDE_TO_REVIEW_TXN}
              </h4>
            </div>
          </div>
        </div>

        <div className="row mt-3 mb-5">
          {/* ====================== LEFT SIDE OF PAGE ====================== */}
          <div className="col-md-6 col-lg-5 mb-4">
            <div className={style.boundingBoxToggleSwitch + " d-lg-flex"}>
              <ToggleSwitch
                label={LabelHelper(lang).SINGLE_TRANSACTION.BOUNDING_BOXES}
                toggleSwitchID={"ODMBoundingBoxSwitch"}
                isChecked={isBoundingBoxEnabled}
                setIsChecked={toggleBoundingBoxSwitch}
                propStyle={{ marginRight: "24px" }}
              />
              <ToggleSwitch
                label={
                  LabelHelper(lang).SINGLE_TRANSACTION.REFLECTION_BOUNDING_BOXES
                }
                toggleSwitchID={"ReflectionBoundingBoxSwitch"}
                isChecked={isReflectionBoundingBoxEnabled}
                setIsChecked={(value: boolean) =>
                  toggleBoundingBoxSwitch(value, true)
                }
              />
            </div>

            <DeviceImage
              classifierPredictions={classifierPredictions}
              imageUrl={imageUrl as string}
              transactionId={transactionId}
              test={test}
              isBoundingBoxEnabled={isBoundingBoxEnabled}
              isReflectionBoundingBoxEnabled={isReflectionBoundingBoxEnabled}
            />
          </div>

          {/* ====================== RIGHT SIDE OF PAGE ====================== */}
          <div className={style.imageDetails + " col-md-6 col-lg-7"}>
            <div className="row">
              <div className="col-md-6">
                <h5 className="mb-1">
                  {LabelHelper(lang).SINGLE_TRANSACTION.CREATED_ON}
                </h5>
                <p>{createdAt}</p>
              </div>

              <div className="col-md-6">
                <h5 className="mb-1">
                  {LabelHelper(lang).SINGLE_TRANSACTION.MAKE_AND_MODEL}
                </h5>
                <p>{getMakeAndModel(deviceMakeAndModel)}</p>
              </div>

              <div className="col-md-6">
                <h5 className="mb-1">
                  {LabelHelper(lang).SINGLE_TRANSACTION.TXN_ID}
                </h5>
                <p>{transactionId}</p>
              </div>

              <div className="col-md-6">
                <h5 className="mb-1">
                  {LabelHelper(lang).SINGLE_TRANSACTION.METHOD}
                </h5>
                <p>{testMethod}</p>
              </div>
            </div>

            <h4 className="mt-4 d-flex">
              <ReactSVG className="me-1" src={ICON_CRACKED_SCREEN} />
              {LabelHelper(lang).SINGLE_TRANSACTION.CRACKED_SCORE}
            </h4>
            <h2 className={style.score}>{result}</h2>

            <div className={style.reflectionScoreContainer + " my-2 d-flex"}>
              <div
                className={
                  style.scoreContainer + " p-3 d-flex justify-content-between"
                }
              >
                <h4 className={style.scoreHeading}>{LabelHelper(lang).SINGLE_TRANSACTION.WITH_REFLECTION}</h4>
                <h4 className={style.score}>
                  {test?.results?.crackedProbabilityWithReflection || "-"}
                </h4>
              </div>
              <div
                className={
                  style.scoreContainer +
                  " ms-3 p-3 d-flex justify-content-between"
                }
              >
                <h4 className={style.scoreHeading}>{LabelHelper(lang).SINGLE_TRANSACTION.NO_REFLECTION}</h4>
                <h4 className={style.score}>
                  {test?.results?.crackedProbabilityWithoutReflection || "-"}
                </h4>
              </div>
            </div>

            <h4 className="mt-4 d-flex">
              <ReactSVG className="me-1" src={ICON_EYE} />
              {LabelHelper(lang).SINGLE_TRANSACTION.HUMAN_EYE_REVIEW}
            </h4>
            <p><span className={style.mandatoryField}>*</span> {LabelHelper(lang).SINGLE_TRANSACTION.DEVICE_CONDITION}</p>
            <RadioButton
              radioValue={radioValue}
              setRadioValue={(value) => { setRadioValue(value); setSubmitCTADisabled(false); }}
              radios={radios}
            />

            <h4 className="mt-4 d-flex">
              <ReactSVG className="me-1" src={ICON_VIEW_LIST} />
              {LabelHelper(lang).SINGLE_TRANSACTION.TAG_CONDITIONS}
            </h4>
            <p>{LabelHelper(lang).SINGLE_TRANSACTION.TAG_CONDITIONS_SUBTEXT}</p>
            <div className={style.tags}>
              <TagList tagOptions={tagOptions} tags={tags}
                setTags={(data) => {setTags(data)
                updateSubmitDisabledStatus();
                }}
              />
            </div>

            {!isCommentDisabled && (
              <div className="form-group mt-3">
                <label className="mb-2" htmlFor="commentsHTML">
                  {LabelHelper(lang).SINGLE_TRANSACTION.COMMENTS}
                </label>
                <textarea
                  style={{ resize: "none" }}
                  className="form-control rounded-0"
                  name="comments"
                  id="trxnComments"
                  cols={20}
                  rows={4}
                  value={comments}
                  onChange={handleCommentsChange}
                  disabled={isCommentDisabled}
                  placeholder="Enter text"
                ></textarea>
              </div>
            )}

            <div className={style.c2pBehaviorField}>
              <h4 className=" mt-4 d-flex">
                <ReactSVG className="me-1" src={ICON_MONITOR} />
                {LabelHelper(lang).SINGLE_TRANSACTION.BEHAVIOR_EXPECTATION}
              </h4>
              <Form.Check
                checked={behaviorDifferentCheckbox}
                type="checkbox"
                onChange={(e) => {
                  setBehaviorDifferentCheckbox(e?.target?.checked);
                  updateSubmitDisabledStatus();
                }
                }
                label={
                  LabelHelper(lang).SINGLE_TRANSACTION.BEHAVIOR_IS_DIFFERENT
                }
              />
            </div>
          </div>
        </div>

      </div>
      <div className={style.submitButton + " d-flex justify-content-between py-3 pe-3"}>
        {/* Empty div for adjustments */}
        <div></div>
        <div className="d-flex align-items-center ">
          <C2Pbutton
            propStyle={{ padding: "12px 24px", marginRight: "20px" }}
            onClick={() => {
              toggleBoundingBoxSwitch(false, false);
              toggleBoundingBoxSwitch(false, true);
              setSubmitCTADisabled(true);
              onPrevious();
            }}
            disabled={hasNoPreviousRecord}
            variant={"link"}
            text={LabelHelper(lang).SINGLE_TRANSACTION.CTA_PREVIOUS}
          />
          <p className="m-0">
            {LabelHelper(lang).SINGLE_TRANSACTION.SINGLE_TXN} {recordNumber}{" "}
            {LabelHelper(lang).SINGLE_TRANSACTION.OF} {totalTransactions}
          </p>
          <C2Pbutton
            propStyle={{ padding: "12px 24px", marginLeft: "20px" }}
            onClick={() => {
              toggleBoundingBoxSwitch(false, false);
              toggleBoundingBoxSwitch(false, true);
              setSubmitCTADisabled(true);
              onNext();
            }}
            disabled={hasNoNextRecord}
            variant={"link"}
            text={LabelHelper(lang).SINGLE_TRANSACTION.CTA_NEXT}
          />
        </div>
        <C2Pbutton
          propStyle={{ padding: "12px 24px", marginRight: "6px" }}
          text={
            hasNoNextRecord
              ? LabelHelper(lang).SINGLE_TRANSACTION.SUBMIT_BTN
              : LabelHelper(lang).SINGLE_TRANSACTION.SUBMIT_AND_CONTINUE_BTN
          }
          onClick={async () => {
            if (hasNoNextRecord) {
              handleOnSubmit();
            } else {
              // after submission continue to the next record
              await handleOnSubmit();
              onNext();
            }
          }}
          disabled={isSubmitCTADisabled}
        />
      </div>

      <Guidelines
        lang={lang}
        handleClose={() => setShowGuidelines(false)}
        showGuidelines={showGuidelines}
      />

      <PageLoader show={loadingStatus === "loading"} lang={lang} />
    </>
  );
};

export default AnalystImageUpdatePage;
